<template>
  <div class="form-container" v-if="!isSubmitted">
    <h3>{{ isEditMode ? "Edit Project" : "Create Project" }}</h3>
    <form @submit.prevent="saveProject">
      <!-- Project Title -->
      <div class="form-group">
        <label>Project Title</label>
        <input type="text" v-model="project.title" placeholder="Enter project title" required maxlength="150" />
      </div>

      <!-- Description -->
      <div class="form-group">
        <label>Description</label>
        <textarea v-model="project.description" placeholder="Enter project description" required maxlength="2000"></textarea>
      </div>

      <!-- Technologies (Comma-separated input) -->
      <div class="form-group">
        <label>Technologies (Max 10)</label>
        <input type="text" v-model="techInput" placeholder="Enter a technology and press Enter" @keydown.enter.prevent="addTechnology" />
        <div class="tech-tags">
          <span v-for="(tech, index) in project.technologies" :key="index" class="tech-tag">
            {{ tech }} <button type="button" @click="removeTechnology(index)">×</button>
          </span>
        </div>
      </div>

      <!-- Repository Link -->
      <div class="form-group">
        <label>Repository Link (Optional)</label>
        <input type="url" v-model="project.repositoryLink" placeholder="https://github.com/user/repo" />
      </div>

      <!-- Demo Link -->
      <div class="form-group">
        <label>Demo Link (Optional)</label>
        <input type="url" v-model="project.demoLink" placeholder="https://your-demo.com" />
      </div>

      <!-- Status -->
      <div class="form-group">
        <label>Status</label>
        <select v-model="project.status">
          <option value="Active">Active</option>
          <option value="Archived">Archived</option>
        </select>
      </div>

      <button type="submit" class="btn-submit">{{ isEditMode ? "Update Project" : "Create Project" }}</button>
    </form>
  </div>

  <!-- Success/Error Message Display -->
  <transition name="fade">
    <p v-if="serverMessage" class="server-message">{{ serverMessage }}</p>
  </transition>
</template>


<script>
import projectClient from "@/services/projectClient";

export default {
  props: {
    isEditMode: { type: Boolean, default: false }, // Edit mode flag
    existingProject: { type: Object, default: null }, // Pre-filled project data
  },
  data() {
    return {
      project: {
        title: "",
        description: "",
        technologies: [],
        repositoryLink: "",
        demoLink: "",
        status: "Active",
      },
      techInput: "", // For technology input handling
      isSubmitted: false, // Controls form visibility
      serverMessage: "", // Holds the response message
    };
  },
  methods: {
    async saveProject() {
      try {
        let response;
        if (this.isEditMode) {
          response = await projectClient.editProject(this.project._id, this.project);
        } else {
          response = await projectClient.createProject(this.project);
        }

        this.serverMessage = response.data.message || "Project saved successfully!";
        this.isSubmitted = true; // Hide form

        // Reset form after 3 seconds
        setTimeout(() => {
          this.serverMessage = "";
          this.isSubmitted = false;
          this.project = { title: "", description: "", technologies: [], repositoryLink: "", demoLink: "", status: "Active" };
        }, 3000);
      } catch (error) {
        this.serverMessage = "Failed to save project.";
        console.error("Failed to save project:", error);

        // Keep message for 3 seconds, then remove
        setTimeout(() => {
          this.serverMessage = "";
        }, 3000);
      }
    },

    addTechnology() {
      if (this.techInput.trim() && this.project.technologies.length < 10) {
        this.project.technologies.push(this.techInput.trim());
        this.techInput = "";
      }
    },

    removeTechnology(index) {
      this.project.technologies.splice(index, 1);
    },
  },
  mounted() {
    if (this.isEditMode && this.existingProject) {
      this.project = { ...this.existingProject };
    }
  },
};
</script>


<style scoped>
@import '../assets/style/FormStyles.css';
</style>
