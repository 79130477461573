<template>
  <!-- The entire application content is rendered via the router -->
  <div data-coreui-theme="dark">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* Add global styles if needed */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
}
</style>
