<template>
  <div class="profile-container">
    <!-- Profile Header -->
    <div class="profile-header">
      <h2>User Profile</h2>
      <p class="subtext">Manage your personal details and account settings</p>
    </div>

    <!-- Profile Card -->
    <div class="profile-card">
      <!-- Profile Picture Section -->
      <div class="profile-avatar">
        <img :src="getProfilePicture" alt="Profile Avatar" class="rounded-circle"/>
        <div class="upload-avatar">
          <label for="avatar-upload" class="upload-label">Change Picture</label>
          <input
            id="avatar-upload"
            type="file"
            @change="uploadProfilePicture"
            accept="image/*"
            class="d-none"
          />
        </div>
      </div>

      <!-- User Details Section -->
      <div class="profile-details">
        <h3>{{ profile.name }}</h3>
        <p><strong>Email:</strong> {{ profile.email }}</p>
        <p><strong>Role:</strong> {{ profile.role }}</p>
        <p><strong>Last Login:</strong> {{ formatDate(profile.lastLogin) }}</p>
      </div>
    </div>

    <!-- Update Profile Form -->
    <div class="profile-form">
      <h4>Edit Profile</h4>
      <form @submit.prevent="updateProfile" class="form-container">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            id="name"
            v-model="profile.name"
            class="form-control"
            placeholder="Enter your name"
            required
          />
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input
            id="email"
            v-model="profile.email"
            class="form-control"
            placeholder="Enter your email"
            disabled
          />
        </div>

        <div class="btn-container">
          <button type="submit" class="btn btn-primary">Update Profile</button>
        </div>
      </form>
    </div>

    <!-- Recent Activity Logs -->
    <div class="recent-logs">
      <h4>Recent Activity</h4>
      <ul class="logs-list">
        <li v-for="log in logs" :key="log._id" class="log-item">
          <span class="log-action">{{ log.action }}</span>
          <span class="log-timestamp">{{ formatDate(log.timestamp) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import userClient from "@/services/userClient";
import logClient from "@/services/logClient";

export default {
  data() {
    return {
      profile: {
        id: "",
        name: "",
        email: "",
        role: "",
        lastLogin: "",
        avatar: "",
      },
      logs: [],
      defaultAvatar: "../assets/defaultProfile.png", // Placeholder avatar
    };
  },
  computed: {
    getProfilePicture() {
      if (this.profile.avatar) {
        return this.profile.avatar.startsWith('http') || this.profile.avatar.startsWith('/')
          ? this.profile.avatar
          : require(`../assets/${this.profile.avatar}`);
      }
      return require('../assets/defaultProfile.png');
    },
  },
  methods: {
    async fetchProfile() {
      try {
        const response = await userClient.getCurrentUser();
        this.profile = response.data;
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    },
    async fetchLogs() {
      try {
        const response = await logClient.fetchUserActivityLogs(5);
        this.logs = response.data.logs;
      } catch (error) {
        console.error("Failed to fetch logs:", error);
      }
    },
    async updateProfile() {
      try {
        const response = await userClient.updateProfile({
          name: this.profile.name,
          email: this.profile.email,
        });

        const updatedUser = response.data.user;

         // Update local storage with new user details
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        storedUser.name = updatedUser.name;
        localStorage.setItem("user", JSON.stringify(storedUser));


      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
    async uploadProfilePicture(event) {
      const file = event.target.files[0];
      if (!file) return;

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await userClient.uploadProfileImage(formData);
        this.profile.avatar = response.data.avatarUrl;

        // Update local storage with new avatar
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        storedUser.avatar = response.data.avatarUrl;
        localStorage.setItem("user", JSON.stringify(storedUser));

      } catch (error) {
        console.error("Failed to upload profile picture:", error);
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
  created() {
    this.fetchProfile();
    this.fetchLogs();
  },
};
</script>

<style scoped>
/* Profile Container */
.profile-container {
  max-width: 900px;
  margin: 2rem auto;
  background: #1e1e2d;
  padding: 2rem;
  border-radius: 12px;
  color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
}

/* Header */
.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-header h2 {
  color: #61dafb;
  font-size: 2rem;
  font-weight: bold;
}

.profile-header .subtext {
  color: #b0b3c0;
  font-size: 1rem;
}

/* Profile Card */
.profile-card {
  display: flex;
  align-items: center;
  gap: 20px;
  background: #2e3748;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

/* Avatar */
.profile-avatar {
  text-align: center;
}

.profile-avatar img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #61dafb;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.upload-avatar {
  margin-top: 10px;
}

.upload-label {
  color: #61dafb;
  cursor: pointer;
  font-size: 0.9rem;
  text-decoration: underline;
}

.upload-label:hover {
  color: #ffffff;
}

/* Profile Details */
.profile-details h3 {
  color: #ffffff;
  margin-bottom: 10px;
}

.profile-details p {
  margin: 5px 0;
  color: #b0b3c0;
}

/* Form */
.profile-form {
  margin-bottom: 30px;
}

.form-container {
  background: #2e3748;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #b0b3c0;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #4e596f;
  background: #1e1e2d;
  color: #ffffff;
}

.form-group input:focus {
  outline: none;
  border-color: #61dafb;
}

.btn-container {
  display: flex;
  justify-content: center; /* Horizontally center the button */
  margin-top: 20px;
}

.btn-primary {
  background-color: #6459e5;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: #6257dc;
}

/* Recent Logs */
.recent-logs {
  margin-top: 20px;
}

.logs-list {
  list-style: none;
  padding: 0;
}

.log-item {
  background: #2e3748;
  padding: 10px 15px;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.log-action {
  color: #ffffff;
}

.log-timestamp {
  color: #61dafb;
  font-size: 0.8rem;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
