<template>
  <div class="not-found d-flex flex-column align-items-center justify-content-center text-center">
    <div class="illustration mb-4">
      <img src="https://via.placeholder.com/300x200" alt="Page Not Found" class="img-fluid" />
    </div>
    <h1 class="display-4 fw-bold text-dark">404 - Page Not Found</h1>
    <p class="fs-5 mb-4 custom-message">
  Well, this is awkward... we looked everywhere, but the page seems to have gone on vacation.
</p>
    <router-link to="/" class="btn btn-primary btn-lg">
      <CIcon name="cil-home" class="me-2" /> Back to Home
    </router-link>
  </div>
</template>

<script>
import { CIcon } from "@coreui/icons-vue";

export default {
  name: "NotFoundView",
  components: {
    CIcon,
  },
};
</script>

<style scoped>
.not-found {
  min-height: 100vh; /* Full height for a centered design */
  background: linear-gradient(135deg,rgb(38, 102, 166),rgb(231, 232, 234)); /* Subtle gradient background */
  color: #2d3748;
  padding: 2rem;
}

/* Illustration styling */
.illustration img {
  max-width: 300px; /* Keep the image responsive */
  margin: 0 auto;
}

/* Typography */
h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.25rem;
  color: #718096;
}

/* Button styling */
.btn {
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
}
.custom-message {
  color: darkblue; /* A vibrant red for a humorous tone */
  font-weight: 500; /* Slightly bolder text */
}
</style>