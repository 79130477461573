<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ isEditMode ? "Edit Post" : "Add New Post" }}
          </h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!-- Title -->
            <div class="mb-3">
              <label for="title" class="form-label">Title</label>
              <input type="text" id="title" v-model="postData.title" class="form-control" required />
            </div>

            <!-- Content -->
            <div class="mb-3">
              <label for="content" class="form-label">Content</label>
              <textarea id="content" v-model="postData.content" class="form-control" rows="6" required></textarea>
            </div>

            <!-- Categories -->
            <div class="mb-3">
              <label for="categories" class="form-label">Categories (comma-separated)</label>
              <input type="text" id="categories" v-model="postData.categories" class="form-control" />
            </div>

            <!-- Image Upload -->
            <div class="mb-3">
              <label class="form-label">Post Images (Maximum 4 images)</label>
              <div class="d-flex flex-wrap gap-2">
                <div v-for="(image, index) in imagePreviews" :key="index" class="image-preview-container">
                  <img :src="image.preview" alt="Post Image" class="img-thumbnail" />
                  <button
                    class="btn btn-danger btn-sm mt-2"
                    @click="removeImage(index)"
                  >
                    Remove
                  </button>
                </div>
              </div>
              <input type="file" @change="handleImageUpload" class="form-control" accept="image/*" multiple />
              <small class="text-danger" v-if="imagePreviews.length > 4">
                Reached maximum images number!
              </small>
            </div>

            <!-- Status -->
            <div class="mb-3">
              <label for="status" class="form-label">Status</label>
              <select id="status" v-model="postData.status" class="form-select">
                <option value="Published">Published</option>
                <option value="Draft">Draft</option>
                <option value="Archived">Archived</option>
              </select>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary rounded-pill px-4" @click="close">Cancel</button>
          <button
            type="button"
            class="btn btn-primary rounded-pill px-4"
            @click="submitForm"
            :disabled="imagePreviews.length > 4"
          >
            {{ isEditMode ? "Save Changes" : "Add Post" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import postClient from "@/services/postClient"; // Service for handling posts

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      postData: { ...this.post },
      imagePreviews: this.post.images || [], // Store multiple image previews
      uploadedImageIds: this.post.imageIds || [], // Store multiple image IDs
    };
  },
  methods: {
    async handleImageUpload(event) {
      const files = event.target.files;
      for (let file of files) {
        try {
          const formData = new FormData();
          formData.append("file", file);

          // Upload image to the backend
          const response = await postClient.uploadImage(formData);
          const fileId = response.data.fileId; // Store the returned file ID
          this.uploadedImageIds.push(fileId);

          // Add to image previews
          const previewUrl = URL.createObjectURL(file);
          this.imagePreviews.push({ preview: previewUrl, id: fileId });
        } catch (error) {
          console.error("Image upload failed:", error);
        }
      }
    },
    async fetchImagePreviews() {
      try {
        // Fetch URLs for existing image IDs from the backend
        const previews = await Promise.all(
          this.uploadedImageIds.map(async (id) => {
            const response = await postClient.getImageUrl(id);
            return { preview: response.data.url, id }; // Adjust as per your backend response
          })
        );
        this.imagePreviews = previews;
      } catch (error) {
        console.error("Error fetching image previews:", error);
      }
    },
    removeImage(index) {
      this.imagePreviews.splice(index, 1);
      this.uploadedImageIds.splice(index, 1);
    },
    submitForm() {
      if (!this.postData.title || !this.postData.content) {
        return;
      }
      if (this.imagePreviews.length > 4) {
        return;
      }

      // Pass the post data with all image IDs
      this.$emit("save", {
        ...this.postData,
        imageIds: this.uploadedImageIds,
      });
    },
    close() {
      this.$emit("close");
    },
  },
  async mounted() {
    // If editing a post, fetch existing image previews
    if (this.isEditMode && this.uploadedImageIds.length) {
      await this.fetchImagePreviews();
    }
  },
};
</script>


<style scoped>
/* Full-screen overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
}

/* Modal Dialog */
.modal-dialog {
  width: 100%;
  max-width: 480px;
  max-height: 85vh;
  background: #2b2d42;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
  flex-direction: column;
}

.modal-body {
  max-height: 65vh; 
  overflow-y: auto; 
  padding: 20px;
  background: #323a49;
  color: #f3f4f7;
}

/* Header */
.modal-header {
  background-color: #4e596f;
  color: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Content */
.modal-content {
  background: #323a49;
  color: #f3f4f7;
  padding: 20px;
}

/* Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background: #2b2d42;
  border-top: 1px solid #3b4055;
}

/* Buttons */
.btn {
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-close:hover {
  color: #c9c9c9;
}

/* Form */
.form-control,
.form-select {
  background: #3b4055;
  color: #ffffff;
  border: 1px solid #6261cc;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

.form-control:focus,
.form-select:focus {
  border-color: #aab3c5;
  outline: none;
  box-shadow: 0 0 8px rgba(98, 97, 204, 0.6);
}

/* Image Preview */
.image-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.image-preview-container img {
  max-width: 100px;
  border-radius: 8px;
  border: 2px solid #6c757d;
}

.img-thumbnail {
  max-width: 100%;
  border-radius: 8px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
