<template>
  <footer class="admin-footer text-light text-center py-3">
    <div>
      <small>
        &copy; 2023 <strong>IDAS Lab</strong>. All rights reserved.
      </small>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AdminFooter",
};
</script>

<style scoped>
.admin-footer {
  height: 60px;
  background-color: #171725;
  font-size: 0.85rem;
  opacity: 0.9;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
}
</style>
