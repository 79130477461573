<template>
    <HeroSection />
    <AboutIDASLab v-intersect />
    <StatisticsSection v-intersect />
    <!--CallToAction v-intersect /-->
</template>

<script>
import { defineAsyncComponent } from "vue";


export default {
  components: {
    HeroSection: defineAsyncComponent(() => import("@/components/HeroSection.vue")),
    AboutIDASLab: defineAsyncComponent(() => import("@/components/AboutIDASLab.vue")),
    StatisticsSection: defineAsyncComponent(() => import("@/components/StatisticsSection.vue")),
    //CallToAction: defineAsyncComponent(() => import("@/components/CallToAction.vue")),
  },
  directives: {
    intersect: {
      mounted(el) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              el.classList.add("fade-in");
              observer.disconnect();
            }
          },
          { threshold: 0.2 }
        );
        observer.observe(el);
      },
    },
  },
};
</script>

<style scoped>
/* Fade-in effect */
.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

[v-intersect] {
  opacity: 0;
  transform: translateY(20px);
}
</style>