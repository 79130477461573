import { createStore } from "vuex";

export default createStore({
  state: {
    user: (() => {
      try {
        const storedUser = localStorage.getItem("user");
        if (!storedUser) return null;
        
        const parsedUser = JSON.parse(storedUser);
        if (parsedUser.expiry && parsedUser.expiry < Date.now()) {
          localStorage.removeItem("user"); // Auto-remove expired session
          return null;
        }
        return parsedUser;
      } catch (error) {
        console.error("Error parsing localStorage user:", error);
        localStorage.removeItem("user"); // Remove corrupt data
        return null;
      }
    })(),
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    userRole: (state) => (state.user ? state.user.role : null),
  },

  mutations: {
    setUser(state, user) {
      if (user && typeof user === "object") {
        const userWithExpiry = {
          ...user,
          expiry: Date.now() + 1000 * 60 * 60, // Set expiry (1 hour)
        };
        state.user = userWithExpiry;
        localStorage.setItem("user", JSON.stringify(userWithExpiry));
      } else {
        console.warn("Invalid user data received:", user);
        state.user = null;
        localStorage.removeItem("user");
      }
    },

    logout(state) {
      state.user = null;
      localStorage.removeItem("user");
      document.cookie = "authToken=; Max-Age=0; path=/"; // Remove auth token
      sessionStorage.clear(); // Clear all session data
      setTimeout(() => {
        location.reload(); // Force full app reset
      }, 100);
    },
  },

  actions: {
    login({ commit }, user) {
      commit("setUser", user);
    },

    logout({ commit }) {
      commit("logout");
    },

    initializeUser({ commit }) {
      return new Promise((resolve) => {
        const user = localStorage.getItem("user");
        if (user) {
          try {
            const parsedUser = JSON.parse(user);
            if (parsedUser.expiry && parsedUser.expiry < Date.now()) {
              commit("logout"); // Auto-remove expired session
            } else {
              commit("setUser", parsedUser);
            }
          } catch (error) {
            console.error("Invalid stored user data:", error);
            commit("logout");
          }
        }
        resolve();
      });
    },
  },
});
