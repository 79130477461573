<template>
  <CCard class="shadow-sm">
    <CCardBody>
      <h6 class="card-title text-center fw-bold">Post Analytics</h6>
      <CChartBar :data="chartData" :options="chartOptions" class="small-chart" />
    </CCardBody>
  </CCard>
</template>

<script>
import { CChartBar } from "@coreui/vue-chartjs";

export default {
  components: { CChartBar },
  props: ["data"],
  computed: {
    chartData() {
      return {
        labels: this.data.labels,
        datasets: [
          {
            label: "Published Posts",
            backgroundColor: "#007bff",
            data: this.data.published,
          },
          {
            label: "Draft Posts",
            backgroundColor: "#fd7e14",
            data: this.data.draft,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: { position: "bottom" } },
      };
    },
  },
};
</script>

<style scoped>
.small-chart {
  height: 200px !important;
}
</style>
