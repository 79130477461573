// services/postClient.js
import apiClient from "./apiClient";

const postClient = {
  /**
   * Fetch a paginated list of posts.
   * @param {Number} page - The current page number.
   * @param {Number} limit - Number of posts per page.
   * @returns {Promise<Object>} API response with paginated posts.
   */
  async fetchPosts(page = 1, limit = 10) {
    try {
      const response = await apiClient.get("/api/posts", { params: { page, limit } });
      return response;
    } catch (error) {
      console.error("Error fetching posts:", error);
      throw error; // Rethrow to be handled in the calling function
    }
  },

  /**
   * Create a new post.
   * @param {Object} post - The post data to create.
   * @returns {Promise<Object>} API response for the created post.
   */
  async createPost(post) {
    try {
      const response = await apiClient.post("/api/posts", post);
      return response;
    } catch (error) {
      console.error("Error creating post:", error);
      throw error;
    }
  },

  /**
   * Update an existing post.
   * @param {String} postId - The ID of the post to update.
   * @param {Object} updatedData - The updated post data.
   * @returns {Promise<Object>} API response for the updated post.
   */
  async updatePost(postId, updatedData) {
    try {
      const response = await apiClient.put(`/api/posts/${postId}`, updatedData);
      return response;
    } catch (error) {
      console.error("Error updating post:", error);
      throw error;
    }
  },

  /**
   * Delete a post permanently.
   * @param {String} postId - The ID of the post to delete.
   * @returns {Promise<Object>} API response for the deleted post.
   */
  async deletePost(postId) {
    try {
      const response = await apiClient.delete(`/api/posts/${postId}`);
      return response;
    } catch (error) {
      console.error("Error deleting post:", error);
      throw error;
    }
  },

  /**
   * Archive a post.
   * @param {String} postId - The ID of the post to archive.
   * @returns {Promise<Object>} API response for the archived post.
   */
  async archivePost(postId) {
    try {
      const response = await apiClient.put(`/api/posts/${postId}/archive`);
      return response;
    } catch (error) {
      console.error("Error archiving post:", error);
      throw error;
    }
  },

  /**
   * Restore an archived post.
   * @param {String} postId - The ID of the post to restore.
   * @returns {Promise<Object>} API response for the restored post.
   */
  async restorePost(postId) {
    try {
      const response = await apiClient.put(`/api/posts/${postId}/restore`);
      return response;
    } catch (error) {
      console.error("Error restoring post:", error);
      throw error;
    }
  },

  /**
   * Upload an image.
   * @param {FormData} formData - The image file in FormData format.
   * @returns {Promise<Object>} API response with the uploaded image URL.
   */
  async uploadImage(formData) {
    try {
      const response = await apiClient.post("/api/uploads", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  },

  /**
   * Toggle the status of a post between "Published" and "Draft".
   * @param {String} postId - The ID of the post.
   * @returns {Promise<Object>} API response with updated post status.
   */
  async togglePostStatus(postId) {
    try {
      const response = await apiClient.put(`/api/posts/${postId}/toggle-status`);
      return response;
    } catch (error) {
      console.error("Error toggling post status:", error);
      throw error;
    }
  },
  async getImageUrl(id) {
    try {
      const response = await apiClient.get(`/api/files/${id}`);
      return response;
    } catch (error) {
      console.error(`Failed to fetch image URL for ID: ${id}`, error);
      throw error;
    }
  },
};

export default postClient;
