<template>
  <CSidebar class="sidebar" data-coreui-theme="dark">
    <!-- Sidebar Brand (Now a Link to Home) -->
    <router-link to="/admin" class="sidebar-brand">
      <CIcon name="cil-speedometer" class="me-2" width="24" />
      <span>IDAS Lab</span>
    </router-link>

    <!-- Sidebar Navigation -->
    <nav class="sidebar-nav">
      <ul>
        <li v-for="item in filteredNavItems" :key="item.name">
          <router-link
            :to="item.path"
            class="nav-link"
            active-class="active"
            exact
          >
            <CIcon :name="item.icon" class="me-2" width="20" />
            <span>{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
    </nav>

    <!-- Sidebar Footer (Logout) -->
    <div class="sidebar-footer">
      <button class="btn btn-outline-light w-100 logout-btn" @click="showLogoutConfirmation">
        <CIcon name="cil-account-logout" class="me-2" width="20" /> Logout
      </button>
    </div>

    <!-- Logout Confirmation Modal -->
    <ConfirmDialog
      v-if="showLogoutModal"
      title="Confirm Logout"
      message="Are you sure you want to log out?"
      @confirm="handleLogout"
      @cancel="showLogoutModal = false"
    />
  </CSidebar>
</template>


<script>
import { CSidebar } from "@coreui/vue";
import { CIcon } from "@coreui/icons-vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import userClient from "@/services/userClient";

export default {
  components: {
    CSidebar,
    CIcon,
    ConfirmDialog,
  },
  data() {
    return {
      showLogoutModal: false,
      user: null,
      // Navigation items
      navItems: [
        { name: "Dashboard", path: "/admin/dashboard", icon: "cil-speedometer", roles: ["Admin"] },
        { name: "Users", path: "/admin/users", icon: "cil-user", roles: ["Admin"] },
        { name: "Projects", path: "/admin/projects", icon: "cil-folder", roles: ["Admin", "Editor", "Viewer"] },
        { name: "Posts", path: "/admin/posts", icon: "cil-description", roles: ["Admin", "Editor"] },
        { name: "Publications", path: "/admin/publications", icon: "cil-book", roles: ["Admin", "Editor", "Viewer"] },
        { name: "Logs", path: "/admin/logs", icon: "cil-notes", roles: ["Admin"] },
      ],
    };
  },
  computed: {
    // Filter navigation items based on the user's role
    filteredNavItems() {
      return this.user ? this.navItems.filter((item) => item.roles.includes(this.user.role)) : [];
    },
  },
  methods: {
    fetchUserRole() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.role) {
        this.user = user;
      } else {
        // Redirect to login if no valid user is found
        window.location.href = "/login";
      }
    },
    showLogoutConfirmation() {
      this.showLogoutModal = true;
    },
    async handleLogout() {
      try {
        await userClient.logoutUser(); // Call Logout API
        this.showLogoutModal = false;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "/login";
      } catch (error) {
        console.error("Logout failed:", error);
        this.showLogoutModal = false;
      }
    },
  },
  created() {
    this.fetchUserRole();
  },
};
</script>



<style scoped>
/* Sidebar styles */
.sidebar {
  width: 240px;
  height: 100vh;
  position: fixed;
  background-color: #1e1e2d;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
}

/* Brand styling */
.sidebar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
  background-color: #171725;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.sidebar-brand:hover {
  background-color: #232334;
  color: #ffffff;
}

/* Navigation */
.sidebar-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-nav li {
  padding: 8px 16px;
}

/* Navigation Links */
.nav-link {
  display: flex;
  align-items: center;
  padding: 12px 18px;
  text-decoration: none;
  color: #b0b3c0;
  border-radius: 6px;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
}

.nav-link.active {
  background-color: #323a49;
  color: #ffffff;
  font-weight: 600;
}

/* Footer */
.sidebar-footer {
  margin-top: auto;
  height: 60px;
  padding: 16px;
  border-top: 1px solid #4a4e57;
  background-color: #171725;
}

/* Logout Button */
.logout-btn {
  font-size: 1rem;
  padding: 10px;
  background: transparent;
  border: 1px solid #b0b3c0;
  color: #b0b3c0;
  transition: all 0.3s ease-in-out;
}

.logout-btn:hover {
  background-color: #ff4d4f;
  color: #ffffff;
  border: 1px solid transparent;
}

/* Hover Animation */
.sidebar-nav li:hover {
  transform: scale(1.03);
  transition: 0.3s ease-in-out;
}

</style>
