<template>
  <div class="admin-layout d-flex">
    <!-- Sidebar -->
      <AdminSidebar />

    <!-- Main Content -->
    <div class="main-content flex-grow-1 d-flex flex-column">
      <!-- Navbar -->
      <AdminNavbar />

      <!-- Notification Container -->
      <ToastificationContainer />

      <!-- Main Section -->
      <main class="content-wrapper flex-grow-1 p-4 text-dark">
        <router-view />
        <slot />
      </main>

      <!-- Footer -->
      <AdminFooter />
    </div>
  </div>
</template>

<script>
import AdminSidebar from "../components/AdminSidebar.vue";
import AdminNavbar from "../components/AdminNavbar.vue";
import AdminFooter from "../components/AdminFooter.vue";
// Import the Toastification container
import { ToastificationContainer } from "vue-toastification";

export default {
  components: {
    AdminSidebar,
    AdminNavbar,
    AdminFooter,
    ToastificationContainer, // Add Toastification container
  },
};
</script>

<style scoped>
/* Main Layout */
.admin-layout {
  min-height: 100vh;
  background: linear-gradient(135deg, #1e293b, #0f172a); /* Subtle gradient background */
}

/* Main Content */
.main-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  
}

/* Content Area */
.content-wrapper {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for the main content */
  background-color: #323a49; /* Light background for contrast */
  padding: 2rem;
  margin: 1rem;
  overflow: auto;
}
</style>
