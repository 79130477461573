<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ isEditMode ? "Edit Publication" : "Add New Publication" }}
          </h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!-- Title -->
            <div class="mb-3">
              <label for="title" class="form-label">Title</label>
              <input type="text" id="title" v-model="publicationData.title" class="form-control" required />
            </div>

            <!-- Abstract -->
            <div class="mb-3">
              <label for="abstract" class="form-label">Abstract</label>
              <textarea id="abstract" v-model="publicationData.abstract" class="form-control" rows="4" required></textarea>
            </div>

            <!-- Authors -->
            <div class="mb-3">
              <label for="authors" class="form-label">Authors</label>
              <input type="text" id="authors" v-model="publicationData.authors" class="form-control" placeholder="Separate names with commas" required />
            </div>

            <!-- Journal -->
            <div class="mb-3">
              <label for="journal" class="form-label">Journal Name</label>
              <input type="text" id="journal" v-model="publicationData.journal" class="form-control" required />
            </div>

            <!-- Publication Date -->
            <div class="mb-3">
              <label for="publication-date" class="form-label">Publication Date</label>
              <input type="date" id="publication-date" v-model="publicationData.publicationDate" class="form-control" />
            </div>

            <!-- Related Link -->
            <div class="mb-3">
              <label for="link" class="form-label">Related Link (DOI, GitHub, etc.)</label>
              <input type="text" id="link" v-model="publicationData.link" class="form-control" />
            </div>

             <!-- Keywords Input -->
             <div class="mb-3">
              <label for="keywords" class="form-label">Keywords</label>
              <input
                type="text"
                id="keywords"
                v-model="keywordInput"
                class="form-control"
                placeholder="Enter keywords and press enter"
                @keyup.enter="addKeyword"
              />
              <div class="keywords-list">
                <span v-for="(keyword, index) in publicationData.keywords" :key="index" class="keyword-badge">
                  {{ keyword }}
                  <button type="button" class="remove-btn" @click="removeKeyword(index)">×</button>
                </span>
              </div>
            </div>

            <!-- File Upload & PDF Preview -->
            <div class="mb-3">
              <label for="file" class="form-label">Upload File (PDF only)</label>
              <input type="file" id="file" @change="handleFileUpload" class="form-control" accept=".pdf" />
              <small class="form-text text-light">Max size: 20MB</small>
            </div>

            <!-- PDF Preview -->
            <div v-if="pdfBlobUrl" class="pdf-preview-container">
              <label class="form-label">PDF Preview</label>
              <iframe :src="pdfBlobUrl" class="pdf-preview" frameborder="0"></iframe>
            </div>

            <!-- Status -->
            <div class="mb-3">
              <label for="status" class="form-label">Status</label>
              <select id="status" v-model="publicationData.status" class="form-select">
                <option value="Published">Published</option>
                <option value="Preprint">Preprint</option>
                <option value="Archived">Archived</option>
              </select>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary rounded-pill px-4" @click="close">
            Cancel
          </button>
          <button type="submit" class="btn btn-primary rounded-pill px-4" @click="submitForm">
            {{ isEditMode ? "Save Changes" : "Add Publication" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicationClient from "@/services/publicationClient";

export default {
  props: {
    publication: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      publicationData: { ...this.publication, keywords: this.publication.keywords || [] },
      pdfBlobUrl: null, // Store PDF preview URL
      keywordInput: "",
    };
  },
  watch: {
    "publicationData.file": {
      immediate: true,
      async handler(fileId) {
        if (fileId) {
          this.fetchFileUrl(fileId); // ✅ Ensure file preview updates when file changes
        }
      },
    },
  },
  methods: {
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await publicationClient.uploadFile(formData);
        this.publicationData.file = response.data.fileId; // ✅ Store uploaded file ID
        this.fetchFileUrl(response.data.fileId); // ✅ Fetch and update the file preview URL
      } catch (error) {
        console.error("File upload failed:", error);
        alert("Failed to upload file. Please try again.");
      }
    },
    async fetchFileUrl(fileId) {
      try {
        const response = await publicationClient.getFileUrl(fileId, { responseType: "blob" });
        const blob = new Blob([response.data], { type: "application/pdf" });
        this.pdfBlobUrl = URL.createObjectURL(blob); // ✅ Generate preview URL
      } catch (error) {
        console.error("Error fetching file URL:", error);
        this.pdfBlobUrl = null; // ✅ Reset preview if fetching fails
      }
    },
    addKeyword() {
      if (this.keywordInput.trim() && !this.publicationData.keywords.includes(this.keywordInput.trim())) {
        this.publicationData.keywords.push(this.keywordInput.trim());
      }
      this.keywordInput = "";
    },
    removeKeyword(index) {
      this.publicationData.keywords.splice(index, 1);
    },
    submitForm() {
      if (!this.publicationData.title || !this.publicationData.abstract || !this.publicationData.authors || !this.publicationData.journal) {
        alert("Title, Abstract, Authors, and Journal are required!");
        return;
      }

      this.$emit("save", this.publicationData);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* Full-screen overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
  overflow: hidden;
}

.modal-body {
  max-height: 60vh; 
  overflow-y: auto; 
}

/* Modal Dialog */
.modal-dialog {
  width: 100%;
  max-width: 520px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  background: #2b2d42;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

/* Header */
.modal-header {
  background-color: #4e596f;
  color: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Content */
.modal-content {
  background: #323a49;
  color: #f3f4f7;
  padding: 20px;
  max-height: 80vh; /* ✅ Set max height to 80% of the viewport */
  overflow-y: auto; /* ✅ Enable vertical scrolling */
}

/* Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background: #2b2d42;
  border-top: 1px solid #3b4055;
}

/* Keywords Styling */
.keywords-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.keyword-badge {
  background: #4e596f;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.remove-btn {
  background: none;
  border: none;
  color: #ff4d4d;
  margin-left: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.remove-btn:hover {
  color: #ff1a1a;
}

/* PDF Preview */
.pdf-preview-container {
  margin-top: 10px;
}

.pdf-preview {
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
</style>
