import apiClient from "./apiClient";

const viewClient = {
  /**
   * Fetch stats data for the landing page (Total Projects, Total Publications, Total Posts, Total Users)
   * @returns {Promise} API response
   */
  async getStats() {
    try {
      const response = await apiClient.get("/api/view/stats");
      return response;
    } catch (error) {
      console.error("Error fetching stats:", error);
      throw new Error("Failed to fetch stats. Please try again.");
    }
  },

  /**
   * Fetch all active projects
   * @param {Number} page - The page number
   * @param {Number} limit - Number of projects per page
   * @param {String} search - Search term for filtering projects
   * @returns {Promise} API response
   */
  async getAllProjects(page = 1, limit = 6, search = "") {
    try {
      const response = await apiClient.get("/api/view/projects", {
        params: { page, limit, search },
      });
      return response;
    } catch (error) {
      console.error("Error fetching projects:", error);
      throw new Error("Failed to fetch projects. Please try again.");
    }
  },

  /**
   * Fetch all non-archived publications
   * @param {Number} page - The page number
   * @param {Number} limit - Number of publications per page
   * @param {String} search - Search term for filtering publications
   * @returns {Promise} API response
   */
  async getAllPublications(page = 1, limit = 6, search = "") {
    try {
      const response = await apiClient.get("/api/view/publications", {
        params: { page, limit, search },
      });
      return response;
    } catch (error) {
      console.error("Error fetching publications:", error);
      throw new Error("Failed to fetch publications. Please try again.");
    }
  },

  /**
   * Fetch all published posts
   * @param {Number} page - The page number
   * @param {Number} limit - Number of posts per page
   * @param {String} search - Search term for filtering posts
   * @returns {Promise} API response
   */
  async getAllPosts(page = 1, limit = 4, search = "") {
    try {
      const response = await apiClient.get("/api/view/posts", {
        params: { page, limit, search },
      });
      return response;
    } catch (error) {
      console.error("Error fetching posts:", error);
      throw new Error("Failed to fetch posts. Please try again.");
    }
  },
};

export default viewClient;
