import apiClient from "./apiClient";

const logClient = {
  /**
   * Fetch general activity logs (Admin actions)
   * @param {Number} page - Current page number
   * @param {Number} limit - Number of logs per page
   * @param {Object} filters - Filters: { user, action, time }
   * @returns {Promise}
   */
  fetchActivityLogs(page = 1, limit = 10, filters = {}) {
    return apiClient.get("/api/logs/recent-activities", {
      params: { page, limit, ...filters },
    });
  },

  /**
   * Fetch file upload logs
   * @param {Number} page - Current page number
   * @param {Number} limit - Number of logs per page
   * @returns {Promise}
   */
  fetchFileUploadLogs(page = 1, limit = 10, filters = {}) {
    return apiClient.get("/api/logs/file-uploads", {
      params: { page, limit, ...filters }, // Pass filters in API request
    });
  },

  /**
   * Fetch system events logs (e.g., system updates, scheduled tasks, etc.)
   * @param {Number} page - Current page number
   * @param {Number} limit - Number of logs per page
   * @returns {Promise}
   */
  fetchSystemEvents(page = 1, limit = 10) {
    return apiClient.get("/api/logs/system-events", {
      params: { page, limit },
    });
  },

  /**
   * Fetch error logs (System errors, API failures, etc.)
   * @param {Number} page - Current page number
   * @param {Number} limit - Number of logs per page
   * @returns {Promise}
   */
  fetchErrorLogs(page = 1, limit = 10) {
    return apiClient.get("/api/logs/error-logs", {
      params: { page, limit },
    });
  },

  /**
   * Fetch logs filtered by user, action, or time range
   * @param {Object} filters - { user, action, time }
   * @returns {Promise}
   */
  fetchFilteredLogs(filters = {}) {
    return apiClient.get("/api/logs/recent-activities", { params: filters });
  },

  fetchUserActivityLogs(limit = 10) {
    return apiClient.get(`/api/logs/recent-user-activites`, {
      params: {limit},
    });
  },

};

export default logClient;
