<template>
  <div class="table-container bg-dark text-light rounded p-3">
    <!-- Table -->
    <table class="table table-dark table-striped table-hover">
      <thead>
        <tr>
          <th v-for="column in columns" :key="column.key" class="text-uppercase">
            {{ column.label }}
          </th>
          <th v-if="hasActions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <!-- Check if rows are available -->
        <template v-if="rows.length > 0">
          <tr v-for="row in rows" :key="row._id">
            <td v-for="column in columns" :key="column.key">
              <!-- Check for scoped slot for the column -->
              <template v-if="$slots[column.key]">
                <slot :name="column.key" :row="row" />
              </template>
              <template v-else>
                <!-- Render raw HTML if provided, else render plain text -->
                <span v-if="column.render" v-html="column.render(row[column.key], row)"></span>
                <span v-else>{{ row[column.key] }}</span>
              </template>
            </td>
            <td v-if="hasActions">
              <slot name="actions" :row="row" />
            </td>
          </tr>
        </template>
        <!-- No data message -->
        <template v-else>
          <tr>
            <td :colspan="columns.length + (hasActions ? 1 : 0)" class="text-center py-4">
              No data available.
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <!-- Pagination -->
    <div class="pagination-controls d-flex justify-content-between mt-3">
      <button
        class="btn btn-outline-secondary"
        :disabled="page === 1"
        @click="onPageChange(-1)"
      >
        Previous
      </button>
      <button
        class="btn btn-outline-secondary"
        :disabled="!hasMorePages"
        @click="onPageChange(1)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableComponent",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    hasMorePages: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["page-change"],
  computed: {
    hasActions() {
      return !!this.$slots.actions;
    },
  },
  methods: {
    onPageChange(direction) {
      this.$emit("page-change", direction);
    },
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}

.table {
  border-radius: 8px;
  overflow: hidden;
}

.text-uppercase {
  text-transform: uppercase;
}

.pagination-controls button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
