<template>
  <div class="form-container" v-if="!isSubmitted">
    <h3>{{ isEditMode ? "Edit User" : "Create User" }}</h3>
    <form @submit.prevent="saveUser">
      <!-- Full Name -->
      <div class="form-group">
        <label>Full Name</label>
        <input type="text" v-model="user.name" placeholder="Enter full name" required />
      </div>

      <!-- Email -->
      <div class="form-group">
        <label>Email</label>
        <input type="email" v-model="user.email" placeholder="Enter email" required />
      </div>

      <!-- Password (Only for new user creation) -->
      <div class="form-group" v-if="!isEditMode">
        <label>Password</label>
        <input type="password" v-model="user.password" placeholder="Enter password" required />
      </div>

      <!-- Role Selection -->
      <div class="form-group">
        <label>Role</label>
        <select v-model="user.role">
          <option value="Admin">Admin</option>
          <option value="Editor">Editor</option>
          <option value="Viewer">Viewer</option>
        </select>
      </div>

      <!-- Status Selection -->
      <div class="form-group">
        <label>Status</label>
        <select v-model="user.status">
          <option value="Active">Active</option>
          <option value="Deactivated">Deactivated</option>
        </select>
      </div>

      <button type="submit" class="btn-submit">{{ isEditMode ? "Update User" : "Create User" }}</button>
    </form>
  </div>

  <!-- Success/Error Message Display -->
  <transition name="fade">
    <p v-if="serverMessage" class="server-message">{{ serverMessage }}</p>
  </transition>
</template>



<script>
import userClient from "@/services/userClient";

export default {
  props: {
    isEditMode: { type: Boolean, default: false }, // Edit mode flag
    existingUser: { type: Object, default: null }, // Pre-filled user data for editing
  },
  data() {
    return {
      user: {
        name: "",
        email: "",
        password: "",
        role: "Viewer",
        status: "Active",
      },
      isSubmitted: false, // Controls form visibility
      serverMessage: "", // Holds the response message
    };
  },
  methods: {
    async saveUser() {
      try {
        let response;
        if (this.isEditMode) {
          response = await userClient.editUserProfile(this.user._id, this.user);
        } else {
          response = await userClient.createUser(this.user);
        }

        this.serverMessage = response.data.message || "User saved successfully!";
        this.isSubmitted = true; // Hide form

        // Reset form and message after 3 seconds
        setTimeout(() => {
          this.serverMessage = "";
          this.isSubmitted = false;
          this.user = { name: "", email: "", password: "", role: "Viewer", status: "Active" };
        }, 3000);
      } catch (error) {
        this.serverMessage = "Failed to save user.";
        console.error("Failed to save user:", error);

        // Keep message for 3 seconds, then remove
        setTimeout(() => {
          this.serverMessage = "";
        }, 3000);
      }
    },
  },
  mounted() {
    if (this.isEditMode && this.existingUser) {
      this.user = { ...this.existingUser };
    }
  },
};
</script>



<style scoped>
@import '../assets/style/FormStyles.css';


</style>
