<template>
  <CCard class="shadow-sm">
    <CCardBody>
      <h6 class="card-title text-center fw-bold">User Roles</h6>
      <CChartPie :data="chartData" :options="chartOptions" class="small-chart" />
    </CCardBody>
  </CCard>
</template>

<script>
import { CChartPie } from "@coreui/vue-chartjs";

export default {
  components: { CChartPie },
  props: ["data"],
  computed: {
    chartData() {
      return {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            backgroundColor: ["#007bff", "#28a745", "#dc3545"],
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: { position: "bottom" } },
      };
    },
  },
};
</script>

<style scoped>
.small-chart {
  height: 200px !important;
}
</style>
