<template>
  <nav class="navbar">
    <div class="nav-container">
      <!-- Brand Logo -->
      <router-link to="/" class="brand">
        <img src="@/assets/logo.png" alt="IDAS Lab Logo" class="logo" />
        <span>IDAS Lab</span>
      </router-link>

      <!-- Navigation Links (Desktop) -->
      <ul class="nav-links" :class="{ 'show-menu': isMenuOpen }">
        <li v-for="item in navItems" :key="item.name">
          <router-link :to="item.path" class="nav-link" exact-active-class="active">
            {{ item.name }}
            <span class="hover-underline"></span>
          </router-link>
        </li>
      </ul>

      <!-- Mobile Menu Toggle Button -->
      <button class="menu-toggle" @click="toggleMenu">
        <i :class="isMenuOpen ? 'fas fa-times' : 'fas fa-bars'"></i>
      </button>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import gsap from "gsap";

export default {
  setup() {
    const isMenuOpen = ref(false);

    const navItems = [
      { name: "Home", path: "/" },
      { name: "Projects", path: "/projects" },
      { name: "Publications", path: "/publications" },
      { name: "Blog", path: "/blog" },
      //{ name: "Team", path: "/team" },
      //{ name: "Contact", path: "/contact" },
    ];

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    // Animate the navbar on page load
    onMounted(() => {
      gsap.from(".navbar", {
        opacity: 0,
        y: -30,
        duration: 1,
        ease: "power2.out",
      });
    });

    // Close menu when route changes (for better UX)
    watch(isMenuOpen, (newValue) => {
      if (!newValue) {
        gsap.to(".nav-links", { opacity: 0, duration: 0.3 });
      } else {
        gsap.from(".nav-links", { opacity: 0, y: 10, duration: 0.3 });
      }
    });

    return { isMenuOpen, navItems, toggleMenu };
  },
};
</script>

<style scoped>
/* Navbar Styling */
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(15px);
  background: rgba(0, 0, 30, 0.5);
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

/* Brand Logo */
.brand {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 45px;
  height: 45px;
  margin-right: 12px;
}

.brand span {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 1px;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 25px;
}

.nav-links li {
  list-style: none;
  position: relative;
}

.nav-link {
  text-decoration: none;
  font-size: 1.1rem;
  color: #b0b3c0;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  padding-bottom: 5px;
  position: relative;
}

/* Hover Animation - Underline Effect */
.nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background: #61dafb;
  transition: all 0.3s ease-in-out;
  transform: translateX(-50%);
}

.nav-link:hover::before {
  width: 100%;
}

/* Active Link */
.active {
  color: #61dafb !important;
}

.nav-link:hover {
  color: #ffffff;
}

/* Mobile Menu Toggle */
.menu-toggle {
  display: none;
  font-size: 1.8rem;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.menu-toggle:hover {
  transform: scale(1.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 15px;
    border-radius: 12px;
    width: 220px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  .nav-links.show-menu {
    display: flex;
  }

  .nav-link {
    padding: 12px 0;
    display: block;
    text-align: center;
  }
}
</style>
