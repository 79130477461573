<template>
  <section class="posts">
    <div class="container">
      <h2 class="section-title">Featured Posts</h2>

      <div v-if="loading" class="loading-text">Loading posts...</div>
      <div v-else-if="posts.length === 0" class="no-posts-text">No posts available.</div>

      <div v-else class="posts-grid">
        <div class="post-card" v-for="post in posts" :key="post._id">
          <div class="card-header">
            <h3 class="post-title">{{ post.title }}</h3>
          </div>

          <div class="card-body">
            <div class="post-image-container">
              <img v-if="post.imageIds && post.imageIds.length > 0" :src="post.imageIds[0].fileUrl" class="post-image" alt="Post Image" />
            </div>
            <p class="post-abstract">
              {{ getShortContent(post.content) }}
              <button class="read-more-btn" @click="openModal(post)">Read More</button>
            </p>
          </div>

          <div class="card-footer">
            <div class="post-categories">
              <span v-for="category in post.categories" :key="category" class="category-badge">
                {{ category }}
              </span>
            </div>
            <span class="post-author">{{ post.author }}</span>
            <span class="post-date">{{ formatDate(post.createdAt) }}</span>
          </div>
          

        </div>
      </div>
    </div>
    
    <!-- Pagination Controls -->
    <div v-if="totalPages > 1" class="pagination">
      <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
    </div>


   <!-- Modal with Image Gallery -->
    <transition name="fade">
    <div v-if="selectedPost" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <button class="close-btn" @click="closeModal">&times;</button>

        <div class="modal-body">
          <!-- Modal Title -->
          <h2 class="modal-title">{{ selectedPost.title }}</h2>

          <!-- Modal Content (Post Content) -->
          <div class="modal-post-content">
            <p>{{ selectedPost.content }}</p>
          </div>

          <!-- Modal Images -->
          <div class="modal-images">
            <div v-for="(image, index) in selectedPost.imageIds" :key="index" class="modal-image-container">
              <img :src="image.fileUrl" class="modal-image" alt="Post Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import viewClient from "@/services/viewClient";  // assuming you have this client to fetch data

export default {
  name: "FeaturedPosts",
  setup() {
    const posts = ref([]);
    const loading = ref(true);
    const selectedPost = ref(null);
    const currentPage = ref(1);
    const itemsPerPage = ref(4);
    const totalPages = ref(1);

    const fetchPosts = async (page) => {
      loading.value = true;  // Show loading indicator while fetching
      try {
        const response = await viewClient.getAllPosts(page, itemsPerPage.value);

        // Update the state with the fetched data
        posts.value = response.data.posts || [];
        totalPages.value = response.data.totalPages;
        currentPage.value = response.data.currentPage;
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        loading.value = false;  // Hide loading indicator after fetching
      }
    };


    const getShortContent = (content) => {
      return content.length > 100 ? content.substring(0, 100) + "..." : content;
    };

    const openModal = (post) => {
      selectedPost.value = post;
    };

    const closeModal = () => {
      selectedPost.value = null;
    };

    const formatDate = (date) => {
      const formattedDate = new Date(date);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Intl.DateTimeFormat('en-US', options).format(formattedDate);
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        fetchPosts(page, itemsPerPage.value);
      }
    };
  
    onMounted(() => {
      fetchPosts(currentPage.value);
    });

    return { posts, loading, selectedPost, goToPage, currentPage, totalPages, getShortContent, openModal, closeModal, formatDate };
  },
};
</script>


<style scoped>
/* Posts Section */
.posts {
  background: linear-gradient(135deg, rgba(0, 0, 30, 0.9), rgba(20, 20, 40, 1));
  padding: 80px 0;
  color: #ffffff;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
}

/* Section Title */
.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #61dafb;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Loading State */
.loading-container {
  text-align: center;
  color: #61dafb;
  font-size: 1.2rem;
}

.loading-text {
  display: inline-block;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

/* No Posts State */
.no-posts-container {
  text-align: center;
  font-size: 1.2rem;
  color: #888;
  padding: 50px 0;
}


/* Posts Grid */
.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 30px;
  justify-items: center;
}

/* Post Card */
.post-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  padding: 20px;
  border-radius: 15px;
  width: 500px;
  text-align: left;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, background-color 0.3s;
}

.post-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(97, 218, 251, 0.3);
}

/* Post Image Container */
.post-image-container {
  position: relative;
  height: 200px;  /* Fixed height for all images */
  overflow: hidden;
  margin-top: 15px;
}

.post-image {
  width: 100%;
  height: 100%;  /* Ensure the image fills the container */
  object-fit: cover;  /* Maintains aspect ratio and fills the container */
  transition: transform 0.3s ease-in-out;
}

.post-image:hover {
  transform: scale(1.05);  /* Slight zoom on hover */
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);  /* Adds shadow on hover */
}

.post-abstract {
  margin-top: 15px;
}

/* Post Author */
.post-author {
  font-size: 1rem;
  color: #b0b0b0;  /* Light grey for subtlety */
  font-weight: 600;  /* Slightly bolder for emphasis */
  letter-spacing: 0.5px;  /* Slightly increased letter-spacing for readability */
  text-transform: capitalize;  /* Capitalize the first letter of each word */
  margin-right: 10px;  /* Space between author and date */
  transition: color 0.3s ease;  /* Smooth transition on hover */
}

/* Hover Effect for Post Author */
.post-author:hover {
  color: #61dafb;  /* Change color to a bright accent on hover */
  cursor: pointer;  /* Show pointer to indicate interactivity */
}

.post-date {
  font-size: 0.9rem;
  color: #b0b0b0;  /* Subtle color for the date */
  font-weight: 400;  /* Regular weight */
  letter-spacing: 0.5px;  /* Slightly increased letter-spacing for readability */
  text-transform: capitalize;  /* Capitalizes the month and year for uniformity */
  transition: color 0.3s ease;  /* Smooth transition on hover */
}

/* Hover Effect for Post Date */
.post-date:hover {
  color: #61dafb;  /* Color change on hover to match theme */
  cursor: pointer;  /* Show pointer on hover */
}

.post-categories {
  margin-top: 15px;
  margin-bottom: 15px;
}

.category-badge {
  background: rgba(97, 218, 251, 0.2);
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #61dafb;
  text-transform: uppercase;
  margin-left: 3px;
  transition: transform 0.2s ease-in-out;
}

.category-badge:hover {
  transform: scale(1.1);
  background: rgba(97, 218, 251, 0.4);
}

/* Read More Button */
.read-more-btn {
  color: #00e5ff;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.read-more-btn:hover {
  color: #4f88d1;
}



/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease-out;
}

/* Modal Content */
.modal-content {
  background: rgba(255, 255, 255, 0.014); /* Glass effect */
  border-radius: 20px;
  padding: 30px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  font-size: 5rem;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px); /* Glass effect */
  border: 2px solid rgba(255, 255, 255, 0.3); /* Border with transparency */
  transition: transform 0.3s ease-in-out;
  animation: slideIn 0.5s ease-out;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 10;
}

.close-btn:hover {
  color: #f00;
}

/* Modal Title */
.modal-title {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.7), 0 0 20px rgba(255, 0, 0, 0.6);
  animation: titleGlow 1.5s infinite alternate;
}

/* Modal Post Content */
.modal-post-content {
  margin-bottom: 20px;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #fff;
  text-align: justify;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

/* Modal Images */
.modal-images {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.modal-image-container {
  width: 100%;
  max-width: 380px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}

.modal-image-container:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
}

/* Add Hover Effects on Image */
.modal-image:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(255, 0, 0, 0.7);
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes titleGlow {
  0% {
    text-shadow: 0 0 10px rgba(255, 0, 0, 0.7), 0 0 20px rgba(255, 0, 0, 0.6);
  }
  50% {
    text-shadow: 0 0 20px rgba(0, 255, 255, 1), 0 0 30px rgba(0, 255, 255, 1);
  }
  100% {
    text-shadow: 0 0 10px rgba(255, 0, 0, 0.7), 0 0 20px rgba(255, 0, 0, 0.6);
  }
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  background-color: #61dafb;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #4f88d1;
}


.pagination span {
  color: #fff;
  font-size: 1rem;
}

.pagination button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

</style>
