<template>
  <div class="dashboard p-4 text-light">
    <h2 class="mb-4 text-center">Admin Dashboard</h2>

    <!-- Tab Navigation -->
    <ul class="nav nav-tabs mb-4">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'overview' }"
          href="#"
          @click.prevent="setActiveTab('overview')"
        >
          Overview
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'analytics' }"
          href="#"
          @click.prevent="setActiveTab('analytics')"
        >
          Analytics
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'logs' }"
          href="#"
          @click.prevent="setActiveTab('logs')"
        >
          Admin Logs
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'uploads' }"
          href="#"
          @click.prevent="setActiveTab('uploads')"
        >
          File Uploads
        </a>
      </li>
    </ul>

    <!-- Tab Content -->
    <div v-if="activeTab === 'overview'" class="tab-content">
      <!-- KPI Summary Cards -->
      <div class="row g-4">
        <MetricsCard title="Total Users" :value="dashboardData.totalUsers" cardClass="card-blue" />
        <MetricsCard title="Total Projects" :value="dashboardData.totalProjects" cardClass="card-purple" />
        <MetricsCard title="Total Posts" :value="dashboardData.totalPosts" cardClass="card-orange" />
        <MetricsCard title="Total Publications" :value="dashboardData.totalPublications" cardClass="card-red" />
        <MetricsCard title="Active Users (Last 30 Days)" :value="dashboardData.activeUsers" cardClass="card-green" />
        <MetricsCard title="Total Uploads" :value="dashboardData.totalUploads" cardClass="card-yellow" />
      </div>
    </div>

    <div v-if="activeTab === 'analytics'" class="tab-content">
      <h4 class="text-center mb-4">User & Content Analytics</h4>
      <div class="row g-4">
        <div class="col-md-6">
          <UserAnalyticsChart :data="userAnalyticsData" />
        </div>
        <div class="col-md-6">
          <UserRolePieChart :data="userRoleData" />
        </div>
        <div class="col-md-6">
          <ProjectBreakdownChart :data="projectAnalyticsData" />
        </div>
        <div class="col-md-6">
          <PostAnalyticsChart :data="postAnalyticsData" />
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'logs'" class="tab-content">
      <h4 class="text-center mb-4">Recent Admin Actions</h4>
      <ActivityLogTable :logs="dashboardData.recentLogs" />
    </div>

    <div v-if="activeTab === 'uploads'" class="tab-content">
      <h4 class="text-center mb-4">Recent File Uploads</h4>
      <FileUploadTable :uploads="dashboardData.recentUploads" />
    </div>
  </div>
</template>

<script>
import MetricsCard from "@/components/MetricsCard.vue";
import UserAnalyticsChart from "@/components/UserAnalyticsChart.vue";
import UserRolePieChart from "@/components/UserRolePieChart.vue";
import ProjectBreakdownChart from "@/components/ProjectBreakdownChart.vue";
import PostAnalyticsChart from "@/components/PostAnalyticsChart.vue";
import ActivityLogTable from "@/components/ActivityLogTable.vue";
import FileUploadTable from "@/components/FileUploadTable.vue";
import dashboardClient from "@/services/dashboardClient";

export default {
  components: {
    MetricsCard,
    UserAnalyticsChart,
    UserRolePieChart,
    ProjectBreakdownChart,
    PostAnalyticsChart,
    ActivityLogTable,
    FileUploadTable,
  },
  data() {
    return {
      activeTab: "overview", // Default active tab
      dashboardData: {},
      userAnalyticsData: { labels: [], values: [] },
      userRoleData: { labels: [], values: [] },
      projectAnalyticsData: { labels: [], activeProjects: [], archivedProjects: [] },
      postAnalyticsData: { labels: [], published: [], draft: [] },
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    async fetchDashboardMetrics() {
      try {
        const response = await dashboardClient.getDashboardMetrics();
        this.dashboardData = response.data.dashboardData;
      } catch (error) {
        console.error("Failed to fetch dashboard metrics:", error);
      }
    },
    async fetchChartsData() {
      try {
        const response = await dashboardClient.getChartsData();

        this.userAnalyticsData = {
          labels: response.data.chartsData.userAnalytics.map((entry) => entry._id || "Unknown"),
          values: response.data.chartsData.userAnalytics.map((entry) => entry.count),
        };

        this.userRoleData = {
          labels: response.data.chartsData.userRole.map((role) => role._id),
          values: response.data.chartsData.userRole.map((role) => role.count),
        };

        const projectMonths = [...new Set(response.data.chartsData.projectAnalytics.map((p) => p._id.month))];
        this.projectAnalyticsData = {
          labels: projectMonths,
          activeProjects: projectMonths.map(
            (month) =>
              response.data.chartsData.projectAnalytics.find((p) => p._id.month === month && p._id.status === "Active")
                ?.count || 0
          ),
          archivedProjects: projectMonths.map(
            (month) =>
              response.data.chartsData.projectAnalytics.find((p) => p._id.month === month && p._id.status === "Archived")
                ?.count || 0
          ),
        };

        const postMonths = [...new Set(response.data.chartsData.postAnalytics.map((p) => p._id.month))];
        this.postAnalyticsData = {
          labels: postMonths,
          published: postMonths.map(
            (month) =>
              response.data.chartsData.postAnalytics.find((p) => p._id.month === month && p._id.status === "Published")
                ?.count || 0
          ),
          draft: postMonths.map(
            (month) =>
              response.data.chartsData.postAnalytics.find((p) => p._id.month === month && p._id.status === "Draft")
                ?.count || 0
          ),
        };
      } catch (error) {
        console.error("Failed to fetch charts data:", error);
      }
    },
  },
  created() {
    this.fetchDashboardMetrics();
    this.fetchChartsData();
  },
};
</script>

<style scoped>
.dashboard {
  background: linear-gradient(135deg, #1e293b, #0f172a);
  border-radius: 16px;
  padding: 2rem;
  animation: fadeIn 0.5s ease-in-out;
}

.nav-tabs {
  border-bottom: 2px solid #4e596f;
}

.nav-link {
  color: #e5e7eb;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}

.nav-link.active {
  background-color: #4e596f;
  color: #fff;
  border-radius: 8px;
}

.tab-content {
  padding: 1.5rem;
  background-color: #2b2d42;
  border-radius: 12px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
