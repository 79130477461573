<template>
  <div class="post-management">
    <h2 class="mb-4 text-light">Post Management</h2>

    <!-- Search and Actions -->
    <div class="d-flex justify-content-between align-items-center mb-3">
      <input
        type="search"
        v-model="search"
        placeholder="Search Posts by title, content, category, or status..."
        class="form-control search-input w-50"
      />
      <div class="d-flex gap-2">
        <button class="btn btn-success text-light" @click="openAddPostModal">
          <CIcon name="cil-plus" class="me-2" /> Add Post
        </button>
        <button class="btn btn-primary" @click="fetchPosts">
          <CIcon name="cil-reload" class="me-2" /> Refresh
        </button>
      </div>
    </div>

    <!-- Posts Table -->
    <TableComponent
      :columns="columns"
      :rows="filteredPosts"
      :page="page"
      :hasMorePages="hasMorePages"
      @page-change="changePage"
    >
      <template #actions="{ row }">
        <div class="d-flex gap-2">
          <button class="btn btn-sm btn-primary" @click="openEditModal(row)">Edit</button>
          <button
            class="btn btn-sm"
            :class="row.status === 'Archived' ? 'btn-success' : 'btn-warning'"
            @click="confirmToggleArchive(row)"
          >
            {{ row.status === 'Archived' ? 'Restore' : 'Archive' }}
          </button>
          <button
            class="btn btn-sm"
            :class="row.status === 'Published' ? 'btn-warning' : 'btn-success'"
            @click="confirmToggleStatus(row)"
          >
            {{ row.status === 'Published' ? 'Move to Draft' : 'Publish' }}
          </button>
          <button class="btn btn-sm btn-danger" @click="confirmDeletePost(row._id)">Delete</button>
        </div>
      </template>
    </TableComponent>

    <!-- Confirm Dialog -->
    <ConfirmDialog
      v-if="isConfirmDialogVisible"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      @confirm="confirmDialog.onConfirm"
      @cancel="closeConfirmDialog"
    />

    <!-- Add & Edit Post Modal -->
    <PostModal
      v-if="showPostModal"
      :post="currentPost"
      :isEditMode="isEditMode"
      @save="savePost"
      @close="closePostModal"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import PostModal from "@/components/PostModal.vue"; // Updated modal component
import userClient from "@/services/userClient";
import postClient from "@/services/postClient";
import { CIcon } from "@coreui/icons-vue";

export default {
  components: { TableComponent, ConfirmDialog, PostModal, CIcon },
  data() {
    return {
      posts: [],
      search: "",
      page: 1,
      limit: 10,
      hasMorePages: true,
      errorMessage: "",
      showPostModal: false,
      isEditMode: false,
      currentPost: null,
      isConfirmDialogVisible: false,
      confirmDialog: { title: "", message: "", onConfirm: null },
      columns: [
        { label: "Title", key: "title" },
        {
          label: "Author",
          key: "author",
          render: (value) => value.name, // Extract the name from the author object
        },
        {
          label: "Categories",
          key: "categories",
          render: (value) => value.join(", "),
        },
        {
          label: "Status",
          key: "status",
          render: (value) =>
            `<span class="badge ${
              value === "Published" ? "bg-success" : value === "Draft" ? "bg-info" : "bg-warning"
            }">${value}</span>`,
        },
      ],
    };
  },
  computed: {
    filteredPosts() {
      return this.search
        ? this.posts.filter(
            (post) =>
              post.title.toLowerCase().includes(this.search.toLowerCase()) ||
              post.status.toLowerCase().includes(this.search.toLowerCase()) ||
              post.content.toLowerCase().includes(this.search.toLowerCase()) ||
              post.categories.join(", ").toLowerCase().includes(this.search.toLowerCase())
          )
        : this.posts;
    },
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await postClient.fetchPosts(this.page, this.limit);
        this.posts = response.data.posts;
        this.hasMorePages = response.data.hasMorePages;
      } catch (error) {
        this.errorMessage = "Failed to load posts.";
        console.error("Failed to fetch posts:", error);
      }
    },
    async getPostAuthor() {
      try {
        // First, check local storage for user data
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          const userData = JSON.parse(storedUser);
          return userData.id || "Unknown"; // Return user ID if available
        }

        // If not in local storage, fetch from API
        const response = await userClient.getCurrentUser();
        return response.data._id || "Unknown";
      } catch (error) {
        console.error("Error fetching post author:", error);
        return "Unknown";
      }
    },

    openAddPostModal() {
      this.isEditMode = false;
      this.getPostAuthor().then((user) => {
        this.currentPost = {
          title: "",
          content: "",
          categories: "",
          status: "Draft",
          author: user,
        };
        this.showPostModal = true;
      });
    },
    openEditModal(post) {
      this.isEditMode = true;
      this.currentPost = { ...post };
      this.showPostModal = true;
    },
    closePostModal() {
      this.showPostModal = false;
    },

    async savePost(post) {
      try {
        if (this.isEditMode) {
          await postClient.updatePost(post._id, post);
        } else {
          await postClient.createPost(post);
        }
        this.closePostModal();
        this.fetchPosts();
      } catch (error) {
        this.errorMessage = "Failed to save post.";
        console.error("Failed to save post:", error);
      }
    },
    confirmDeletePost(postId) {
      this.showConfirmDialog("Delete Post", "Are you sure?", () => this.deletePost(postId));
    },
    async deletePost(postId) {
      try {
        await postClient.deletePost(postId);
        this.fetchPosts();
        this.isConfirmDialogVisible = false;
      } catch (error) {
        this.errorMessage = "Failed to delete post.";
        console.error("Failed to delete post:", error);
        this.isConfirmDialogVisible = false;
      }
    },
    confirmToggleArchive(post) {
      const action = post.status === "Archived" ? "restore" : "archive";
      this.showConfirmDialog(`${action} Post`, `Are you sure you want to ${action} this post?`, () =>
        this.toggleArchiveStatus(post)
      );
    },
    async toggleArchiveStatus(post) {
      try {
        if (post.status === "Archived") {
          await postClient.restorePost(post._id);
          this.isConfirmDialogVisible = false;
        } else {
          await postClient.archivePost(post._id);
          this.isConfirmDialogVisible = false;
        }
        this.fetchPosts();
      } catch (error) {
        this.errorMessage = "Failed to update post status.";
        console.error("Failed to update post status:", error);
      }
    },
    confirmToggleStatus(post) {
      const newStatus = post.status === "Published" ? "Draft" : "Published";
      this.showConfirmDialog("Change Post Status", `Move this post to ${newStatus}?`, async () => {
        await postClient.togglePostStatus(post._id);
        this.fetchPosts();
        this.isConfirmDialogVisible = false;
      });
    },
    showConfirmDialog(title, message, onConfirm) {
      this.confirmDialog = { title, message, onConfirm };
      this.isConfirmDialogVisible = true;
    },
    closeConfirmDialog() {
      this.isConfirmDialogVisible = false;
    },
    changePage(direction) {
      this.page += direction;
      this.fetchPosts();
    },
  },
  created() {
    this.fetchPosts();
  },
};
</script>

<style scoped>
.post-management {
  padding: 2rem;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  border-radius: 12px;
  animation: fadeIn 0.5s ease-in-out;
}

.search-input {
  border-radius: 8px;
  padding: 0.5rem;
  background-color: #323a49;
  color: #f3f4f7;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>