<template>
  <div class="container-fluid user-management">
    <h2 class="mb-4 text-light">User Management</h2>

    <!-- Search & Actions -->
    <div class="d-flex justify-content-between align-items-center mb-3">
      <input
        type="search"
        v-model="search"
        placeholder="Search users..."
        class="form-control search-input w-50"
      />
      <div class="d-flex gap-2">
        <button class="btn btn-success text-light" @click="openAddUserModal">
          <CIcon name="cil-user-follow" class="me-2" /> Add User
        </button>
        <button class="btn btn-primary" @click="fetchUsers">
          <CIcon name="cil-reload" class="me-2" /> Refresh
        </button>
      </div>
    </div>

    <!-- Error Notification -->
    <div v-if="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ errorMessage }}
      <button type="button" class="btn-close" @click="errorMessage = ''"></button>
    </div>

    <!-- User Table -->
    <TableComponent
      :columns="columns"
      :rows="filteredUsers"
      :page="page"
      :hasMorePages="hasMorePages"
      @page-change="changePage"
    >
      <!-- Scoped Slot for Role Column -->
      <template #role="{ row }">
        <select
          v-model="row.role"
          class="form-select form-select-sm w-auto"
          @change="confirmRoleChange(row)"
        >
          <option value="Admin">Admin</option>
          <option value="Editor">Editor</option>
          <option value="Viewer">Viewer</option>
        </select>
      </template>

      <!-- Actions Column -->
      <template #actions="{ row }">
        <div class="d-flex gap-2">
          <button class="btn btn-sm btn-primary" @click="openEditModal(row)">Edit</button>
          <button class="btn btn-sm btn-secondary" @click="openChangePasswordModal(row)">Edit Password</button>
          <button
            class="btn btn-sm"
            :class="row.status === 'Active' ? 'btn-warning' : 'btn-success'"
            @click="confirmStatusToggle(row)"
          >
            {{ row.status === "Active" ? "Deactivate" : "Activate" }}
          </button>
          <button class="btn btn-sm btn-danger" @click="confirmDelete(row)">Delete</button>
        </div>
      </template>
    </TableComponent>

    <!-- Confirmation Dialog -->
    <ConfirmDialog
      v-if="isConfirmDialogVisible"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      @confirm="confirmDialog.onConfirm"
      @cancel="closeConfirmDialog"
    />

    <!-- Add & Edit User Modal -->
    <UserModal
      v-if="showUserModal"
      :user="currentUser"
      :isEditMode="isEditMode"
      @save="saveUser"
      @close="closeUserModal"
    />

    <ChangePasswordModal
      v-if="showPasswordModal"
      :userId="currentUser"
      @close="closeChangePasswordModal"
      @change-password="handleChangePassword"
    />

  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import ChangePasswordModal from "@/components/ChangePasswordModal.vue";
import UserModal from "@/components/UserModal.vue"; // Reusable modal for Add/Edit
import userClient from "@/services/userClient";
import { CIcon } from "@coreui/icons-vue";

export default {
  components: {
    TableComponent,
    ConfirmDialog,
    ChangePasswordModal,
    UserModal,
    CIcon,
  },
  data() {
    return {
      users: [],
      search: "",
      page: 1,
      limit: 10,
      hasMorePages: true,
      errorMessage: "",
      showUserModal: false,
      isEditMode: false,
      currentUser: null,
      showPasswordModal: false,
      isConfirmDialogVisible: false,
      confirmDialog: { title:"", message:"" , onConfirm: null },
      columns: [
      { 
        label: "Avatar", 
        key: "avatar", 
        render: (value) => 
                    `<img src="${value}" class="avatar" alt="User Avatar">`
      },  
      { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Role", key: "role" },
        {
          label: "Status",
          key: "status",
          render: (value) =>
            `<span class="badge ${
              value === "Active" ? "bg-success" : "bg-warning"
            }">${value}</span>`,
        },
      ],
    };
  },
  computed: {
    filteredUsers() {
      return this.search
        ? this.users.filter(
            (user) =>
              user.name.toLowerCase().includes(this.search.toLowerCase()) ||
              user.email.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.users;
    },
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await userClient.fetchUsers(this.page, this.limit);
        this.users = response.data;
        
        this.users = response.data.map(user => ({
          ...user,
          avatar: user.avatar || require("../assets/defaultProfile.png") // Fallback image if no avatar exists
        }));
        
        this.hasMorePages = response.data.hasMorePages;
        
      } catch (error) {
        this.errorMessage = "Failed to load users.";
        console.error("Failed to fetch users:", error);
      }
    },
    openAddUserModal() {
      this.isEditMode = false;
      this.currentUser = { name: "", email: "", role: "Viewer", password: "" };
      this.showUserModal = true;
    },
    openEditModal(user) {
      this.isEditMode = true;
      this.currentUser = { ...user };
      this.showUserModal = true;
    },
    closeUserModal() {
      this.showUserModal = false;
    },
    async saveUser(user) {
      try {
        if (this.isEditMode) {
          await userClient.editUserProfile(user._id, user);
        } else {
          await userClient.createUser(user);
        }
        this.closeUserModal();
        this.fetchUsers();
      } catch (error) {
        this.errorMessage = "Failed to save user.";
        console.error("Failed to save user:", error);
      }
    },
    openChangePasswordModal(user) {
      this.currentUser = user._id;
      this.showPasswordModal = true;
    },
    closeChangePasswordModal() {
      this.showPasswordModal = false;
      this.currentUser = null;
    },
    async handleChangePassword({ userId, newPassword,  confirmNewPassword}) {
      try {
        await userClient.editPassword(userId, { newPassword,  confirmNewPassword});
        this.closeChangePasswordModal();
      } catch (error) {
        console.error("Failed to change password:", error);
      }
    },
    confirmDelete(user) {
      this.showConfirmDialog("Delete User",`Are you sure to delete user ${user.name}?`, () => this.deleteUser(user._id));
    },
    async deleteUser(userId) {
      try {
        await userClient.permanentDeleteUser(userId);
        this.fetchUsers();
        this.isConfirmDialogVisible = false;
      } catch (error) {
        this.errorMessage = "Failed to delete user.";
        console.error("Failed to delete user:", error);
      }
    },
    confirmStatusToggle(user) {
      const action = user.status === "Active" ? "Deactivate" : "Activate";
      this.showConfirmDialog(`${action} User`, `Are you sure you want to ${action.toLowerCase()} this user?`, () =>
        this.toggleUserStatus(user)
      );
    },
    async toggleUserStatus(user) {
      try {
        await userClient.toggleUserStatus(user._id, user.status);
        this.fetchUsers();
        this.isConfirmDialogVisible = false;
      } catch (error) {
        this.errorMessage = "Failed to update user status.";
        console.error("Failed to update user status:", error);
      }
    },
    confirmRoleChange(user) {
      this.showConfirmDialog(
        "Change User Role",
        `Are you sure you want to change this user's role to ${user.role}?`,
        () => this.changeUserRole(user._id, user.role)
      );
    },
    async changeUserRole(userId, role) {
      try {
        await userClient.updateUserRole(userId, role);
        this.fetchUsers();
        this.isConfirmDialogVisible = false;
      } catch (error) {
        this.errorMessage = "Failed to update user role.";
        console.error("Failed to update user role:", error);
      }
    },
    showConfirmDialog(title, message, onConfirm) {
      this.confirmDialog = { title ,message, onConfirm };
      this.isConfirmDialogVisible = true;
    },
    closeConfirmDialog() {
      this.isConfirmDialogVisible = false;
    },
    changePage(direction) {
      this.page += direction;
      this.fetchUsers();
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
.user-management {
  padding: 2rem;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  border-radius: 12px;
  animation: fadeIn 0.5s ease-in-out;
}

.search-input {
  border-radius: 8px;
  padding: 0.5rem;
  background-color: #323a49;
  color: #f3f4f7;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
