<template>
  <table class="table table-striped table-dark">
    <thead>
      <tr>
        <th>User</th>
        <th>Action</th>
        <th>Timestamp</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="log in logs" :key="log._id">
        <td>{{ log.user }}</td>
        <td>{{ log.action }}</td>
        <td>{{ new Date(log.timestamp).toLocaleString() }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["logs"],
};
</script>
