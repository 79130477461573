<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ isEditMode ? "Edit User" : "Add New User" }}
          </h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="name" class="form-label">Name</label>
              <input
                type="text"
                id="name"
                v-model="userData.name"
                class="form-control rounded-lg"
                required
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input
                type="email"
                id="email"
                v-model="userData.email"
                class="form-control rounded-lg"
                required
              />
            </div>
            <div class="mb-3" v-if="!isEditMode">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                id="password"
                v-model="userData.password"
                class="form-control rounded-lg"
                required
              />
            </div>
            <div class="mb-3">
              <label for="role" class="form-label">Role</label>
              <select id="role" v-model="userData.role" class="form-select rounded-lg">
                <option value="Admin">Admin</option>
                <option value="Editor">Editor</option>
                <option value="Viewer">Viewer</option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary rounded-pill px-4" @click="close">
            Cancel
          </button>
          <button type="button" class="btn btn-primary rounded-pill px-4" @click="submitForm">
            {{ isEditMode ? "Save Changes" : "Add User" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      userData: { ...this.user }, // Create a local copy of the user object
    };
  },
  methods: {
    submitForm() {
      if (!this.userData.name || !this.userData.email) {
        alert("Name and email are required!");
        return;
      }
      this.$emit("save", this.userData);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* Full-screen overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
}

/* Modal Dialog */
.modal-dialog {
  width: 100%;
  max-width: 480px;
  background: #2b2d42;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

/* Header */
.modal-header {
  background-color: #4e596f;
  color: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Content */
.modal-content {
  background: #323a49;
  color: #f3f4f7;
  padding: 20px;
}

/* Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background: #2b2d42;
  border-top: 1px solid #3b4055;
}

/* Buttons */
.btn {
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-close:hover {
  color: #c9c9c9;
}

/* Form */
.form-control,
.form-select {
  background: #3b4055;
  color: #ffffff;
  border: 1px solid #6261cc;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

.form-control:focus,
.form-select:focus {
  border-color: #aab3c5;
  outline: none;
  box-shadow: 0 0 8px rgba(98, 97, 204, 0.6);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
