import apiClient from "./apiClient";

const dashboardClient = {
  getDashboardMetrics() {
    return apiClient.get("/api/admin/dashboard-metrics");
  },
  getChartsData() {
    return apiClient.get("/api/admin/charts-data");
  },
};

export default dashboardClient;
