<template>
  <CCard class="shadow-sm">
    <CCardBody>
      <h6 class="card-title text-center fw-bold">New Users Per Month</h6>
      <CChartLine :data="chartData" :options="chartOptions" class="small-chart" />
    </CCardBody>
  </CCard>
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";

export default {
  components: { CChartLine },
  props: ["data"],
  computed: {
    chartData() {
      return {
        labels: this.data.labels,
        datasets: [
          {
            label: "New Users",
            backgroundColor: "rgba(0, 123, 255, 0.2)",
            borderColor: "#007bff",
            data: this.data.values,
            tension: 0.4,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: { display: false } },
      };
    },
  },
};
</script>

<style scoped>
.small-chart {
  height: 200px !important;
}
</style>
