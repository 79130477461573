<template>
  <header class="navbar navbar-expand-lg text-light px-4 shadow-sm d-flex align-items-center">
    <!-- Toggler Button for Mobile -->
    <button
      class="navbar-toggler btn btn-link text-light d-lg-none me-3"
      type="button"
      @click="toggleMenu"
    >
      <CIcon :name="menuVisible ? 'cil-x-circle' : 'cil-menu'" width="20" />
    </button>

    <!-- Breadcrumb Navigation -->
    <nav aria-label="breadcrumb" class="navbar-brand m-0 p-0 d-flex align-items-center">
      <ol class="breadcrumb m-0 p-0">
        <li
          v-for="(crumb, index) in breadcrumbs"
          :key="index"
          class="breadcrumb-item"
        >
          <router-link
            v-if="index < breadcrumbs.length - 1"
            :to="crumb.path"
            class="text-decoration-none"
          >
            {{ crumb.name }}
          </router-link>
          <span v-else>{{ crumb.name }}</span>
        </li>
      </ol>
    </nav>

    <!-- Account Dropdown -->
    <div class="dropdown ms-auto">
      <button
        class="btn btn-link text-light d-flex align-items-center"
        id="accountDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @mouseover="loadUserProfile"
        :class="{ 'loading': isLoading }"
      >
        <div class="profile-img-wrapper">
          <img
            :src="getProfilePicture"
            alt="Profile"
            class="rounded-circle me-2"
          />
          <span v-if="isLoading" class="spinner-border text-light spinner-border-sm" role="status"></span>
        </div>
        <span v-if="!isLoading" class="d-none d-md-inline">{{ user.name }}</span>
      </button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="accountDropdown">
        <li>
          <router-link to="/admin/profile" class="dropdown-item">Profile</router-link>
        </li>
        <li>
          <router-link to="/admin/settings" class="dropdown-item">Account Settings</router-link>
        </li>
        <li>
          <hr class="dropdown-divider" />
        </li>
        <li>
          <a class="dropdown-item" @click="showLogoutConfirmation">Logout</a>
        </li>
      </ul>
    </div>
  </header>

  <!-- Logout Confirmation Modal -->
  <ConfirmDialog
      v-if="showLogoutModal"
      title="Confirm Logout"
      message="Are you sure you want to log out?"
      @confirm="handleLogout"
      @cancel="showLogoutModal = false"
    />

</template>


<script>
import { CIcon } from "@coreui/icons-vue";
import userClient from "@/services/userClient";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  components: {
    CIcon,
    ConfirmDialog,
  },
  data() {
    return {
      showLogoutModal: false, 
      menuVisible: false, // Tracks whether the menu is visible
      isMobile: window.innerWidth < 992, // Determines if the device is mobile
      theme: "light", // Theme state
      user: {
        name: "Loading...", // Placeholder until the data is fetched
        avatar: null, // Profile picture URL or null
      },
      defaultProfilePicture: "../assets/defaultProfile.png",
      isLoading: true, // Loading state for smooth experience
    };
  },
  computed: {
    breadcrumbs() {
      const { matched } = this.$route;
      return matched.map((route) => ({
        name: route.meta.breadcrumb || route.name,
        path: route.path,
      }));
    },
    themeIcon() {
      return this.theme === "dark" ? "cil-sun" : "cil-moon";
    },
    getProfilePicture() {
      if (this.user.avatar) {
        return this.user.avatar.startsWith('http') || this.user.avatar.startsWith('/')
          ? this.user.avatar
          : require(`../assets/${this.user.avatar}`);
      }
      return require('../assets/defaultProfile.png');
    },
  },
  methods: {
    async loadUserProfile() {
      if (!this.isLoading) return; // Avoid duplicate fetches
      try {
        // Simulate API call or delay
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Replace this with actual API fetch logic
        this.user = JSON.parse(localStorage.getItem("user")) || {
          name: "Admin",
          avatar: "../assets/defaultProfile.png",
        };
      } catch (error) {
        console.error("Error loading user profile:", error);
      } finally {
        this.isLoading = false; // Stop loading spinner
      }
    },
    
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 992;
      if (!this.isMobile) {
        this.menuVisible = false; // Reset menu visibility when resizing to desktop
      }
    },
    showLogoutConfirmation() {
      this.showLogoutModal = true;
    },
    async handleLogout() {
      try {
        await userClient.logoutUser(); // Call Logout API

        // Clear user session
        localStorage.removeItem("user");
        localStorage.removeItem("token");

        this.showLogoutModal = false;
        // Redirect to login
        window.location.href = "/login";
      } catch (error) {
        console.error("Logout failed:", error);
        this.showLogoutModal = false;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.loadUserProfile(); 
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadUserProfile(); // Fetch user profile on mount
  },
};
</script>
<style scoped>
.navbar {
  height: 60px;
  flex-shrink: 0;
  background-color: #171725;
}

.navbar-toggler {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.breadcrumb {
  background-color: transparent;
}

.nav-link {
  padding: 0.5rem 1rem;
  color: #b0b3c0;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ffffff;
}

img.rounded-circle {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.menu-container {
  display: none;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: 0;
}

.menu-container.show {
  display: flex !important;
  max-height: 500px;
  flex-direction: column;
}

@media (min-width: 992px) {
  .menu-container {
    display: flex !important;
    max-height: none;
    flex-direction: row;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0;
  background: transparent;
  list-style: none;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Breadcrumb items */
.breadcrumb-item {
  color: #b0b3c0;
  font-weight: 500;
  text-transform: capitalize;
}

/* Breadcrumb links */
.breadcrumb-item a {
  color: #61dafb;
  text-decoration: none;
  transition: color 0.2s ease;
}

.breadcrumb-item a:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Separator */
.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  color: #b0b3c0;
  margin: 0 0.5rem;
  font-weight: 400;
}

/* Last breadcrumb item */
.breadcrumb-item:last-child {
  color: #ffffff;
  font-weight: 600;
}

#accountDropdown {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Add space between the image and name */
  padding: 0.5rem 1rem;
  text-align: left;
  transition: background-color 0.3s ease;
}


img.rounded-circle {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
}

#accountDropdown.loading {
  pointer-events: none;
  opacity: 0.5;
}

/* Profile Image Wrapper */
.profile-img-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-img-wrapper img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
}

.spinner-border {
  margin-left: 8px;
}

/* Dropdown Menu */
.dropdown-menu {
  min-width: 200px;
  padding: 0.5rem;
}

.dropdown-item {
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  color: #e8e7e7;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #07294b;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
