<template>
  <div class="settings-container">
    <!-- Header -->
    <div class="settings-header">
      <h2>Account Settings</h2>
      <p class="subtext">Manage your account, privacy, and preferences</p>
    </div>

    <!-- Change Password Section -->
    <div class="settings-section">
      <h4>Change Password</h4>
      <form @submit.prevent="changePassword" class="form-container">
        <div class="form-group">
          <label for="current-password">Current Password</label>
          <input
            id="current-password"
            type="password"
            v-model="passwordData.currentPassword"
            class="form-control"
            placeholder="Enter your current password"
            required
          />
        </div>

        <div class="form-group">
          <label for="new-password">New Password</label>
          <input
            id="new-password"
            type="password"
            v-model="passwordData.newPassword"
            class="form-control"
            placeholder="Enter a new password"
            required
          />
        </div>

        <div class="btn-container">
          <button type="submit" class="btn btn-primary">Update Password</button>
        </div>
      </form>
    </div>

    <!-- Deactivate Account Section -->
    <div class="settings-section danger-section">
      <h4>Deactivate Account</h4>
      <p class="text-warning">Temporarily deactivate your account. Please contact the support to reactivate your account.</p>
      <div class="btn-container">
        <button class="btn btn-warning" @click="confirmDeactivateAccount">Deactivate My Account</button>
      </div>
    </div>

    <!-- Confirm Deactivation Dialog -->
    <ConfirmDialog
      v-if="showDeactivateConfirm"
      title="Confirm Deactivation"
      message="Are you sure you want to deactivate your account? You can log in anytime to reactivate it."
      @confirm="deactivateAccount"
      @cancel="showDeactivateConfirm = false"
    />
  </div>
</template>

<script>
import userClient from "@/services/userClient";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog },
  data() {
    return {
      passwordData: {
        currentPassword: "",
        newPassword: "",
      },
      showDeactivateConfirm: false,
    };
  },
  methods: {
    async changePassword() {
      try {
        await userClient.changePassword(this.passwordData);
      } catch (error) {
        console.error("Failed to change password:", error);
      }
    },
    confirmDeactivateAccount() {
      this.showDeactivateConfirm  = true;
    },
    async deactivateAccount() {
      try {
        // Deactivate user account
        await userClient.softDeleteUser();

        // Logout to clear session & cookie
        await userClient.logoutUser();

        // Clear local storage
        localStorage.removeItem("user");
        localStorage.removeItem("token");

        // Redirect to login page
        this.showDeactivateConfirm = false;
        window.location.href = "/login";
      } catch (error) {
        console.error("Failed to deactivate account:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Container */
.settings-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #1e1e2d;
  border-radius: 12px;
  color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
}

/* Header */
.settings-header {
  text-align: center;
  margin-bottom: 20px;
}

.settings-header h2 {
  color: #61dafb;
  font-size: 2rem;
  font-weight: bold;
}

.settings-header .subtext {
  color: #b0b3c0;
  font-size: 1rem;
}

/* Section */
.settings-section {
  margin-top: 20px;
  padding: 20px;
  background: #2e3748;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.settings-section h4 {
  margin-bottom: 15px;
  color: #ffffff;
}

.settings-section p {
  margin-bottom: 15px;
  color: #b0b3c0;
}

.danger-section {
  border: 1px solid #ff4d4f;
}

/* Form */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group label {
  font-size: 0.9rem;
  color: #b0b3c0;
  margin-bottom: 8px;
}

.form-group input {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #4e596f;
  background: #1e1e2d;
  color: #ffffff;
}

.form-group input:focus {
  outline: none;
  border-color: #61dafb;
}

/* Buttons */
.btn-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-primary {
  background-color: #6459e5;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #6257dc;
}

.btn-warning {
  background-color: #ffc107;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  color: #000000;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-warning:hover {
  background-color: #ffb300;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
