<template>
  <div class="admin-home-container">
    <!-- Page Header -->
    <div class="admin-header">
      <h1>Welcome, {{ userName }}</h1>
      <p class="subtext">
        {{ role === "Viewer" ? "Feel free to explore the projects and publications." : "Manage users, projects, posts, and publications with ease." }}
      </p>
    </div>

    <!-- Action Buttons (Only for Admin & Editor) -->
    <div class="button-grid" v-if="role !== 'Viewer'">
      <button v-if="role === 'Admin'" @click="activeForm = 'user'" class="action-btn user-btn">
        <CIcon name="cil-user" class="icon" />
        <span>Create User</span>
      </button>

      <button v-if="role !== 'Viewer'" @click="activeForm = 'project'" class="action-btn project-btn">
        <CIcon name="cil-folder" class="icon" />
        <span>Create Project</span>
      </button>

      <button v-if="role !== 'Viewer'" @click="activeForm = 'post'" class="action-btn post-btn">
        <CIcon name="cil-description" class="icon" />
        <span>Create Post</span>
      </button>

      <button v-if="role !== 'Viewer'" @click="activeForm = 'publication'" class="action-btn publication-btn">
        <CIcon name="cil-book" class="icon" />
        <span>Create Publication</span>
      </button>
    </div>

    <!-- Dynamic Form Section -->
    <div class="form-container" v-if="role !== 'Viewer'">
      <CreateUserForm v-if="activeForm === 'user'" />
      <CreateProjectForm v-if="activeForm === 'project'" />
      <CreatePostForm v-if="activeForm === 'post'" />
      <CreatePublicationForm v-if="activeForm === 'publication'" />
    </div>
  </div>
</template>



<script>
import { CIcon } from "@coreui/icons-vue";
import CreateUserForm from "@/components/CreateUserForm.vue";
import CreateProjectForm from "@/components/CreateProjectForm.vue";
import CreatePostForm from "@/components/CreatePostForm.vue";
import CreatePublicationForm from "@/components/CreatePublicationForm.vue";

export default {
  components: {
    CIcon,
    CreateUserForm,
    CreateProjectForm,
    CreatePostForm,
    CreatePublicationForm,
  },
  data() {
    return {
      userName: "Admin", // Default fallback
      role: "",
      activeForm: null, // Tracks the selected form
    };
  },
  mounted() {
    // Retrieve user from localStorage
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      this.userName = userData.name || "User";
      this.role = userData.role || "Viewer"; // Default role is Viewer if not found
    }
  },
};
</script>




<style scoped>
@import '../assets/style/FormStyles.css';

/* Container */
.admin-home-container {
  max-width: 900px;
  margin: 2rem auto;
  text-align: center;
  padding: 2rem;
  background: #1e1e2d;
  border-radius: 12px;
  color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
}

/* Header */
.admin-header h1 {
  color: #61dafb;
  font-size: 2.2rem;
  font-weight: bold;
}

.admin-header .subtext {
  color: #b0b3c0;
  font-size: 1rem;
  margin-bottom: 20px;
}

/* Button Grid */
.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

/* Action Buttons */
.action-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 12px;
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  background-size: 200% 200%;
  border: none;
  cursor: pointer;
}

/* Hover Effect */
.action-btn:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Icon Styling */
.icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

/* Stylish Button Colors */
.user-btn {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
}

.project-btn {
  background: linear-gradient(135deg, #ff7eb3 0%, #ff758c 100%);
}

.post-btn {
  background: linear-gradient(135deg, #f7971e 0%, #ffd200 100%);
}

.publication-btn {
  background: linear-gradient(135deg, #43cea2 0%, #185a9d 100%);
}

/* Hover Effects */
.user-btn:hover {
  background: linear-gradient(135deg, #2575fc 0%, #6a11cb 100%);
}

.project-btn:hover {
  background: linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%);
}

.post-btn:hover {
  background: linear-gradient(135deg, #ffd200 0%, #f7971e 100%);
}

.publication-btn:hover {
  background: linear-gradient(135deg, #185a9d 0%, #43cea2 100%);
}

/* Form Container */
.form-container {
  margin-top: 30px;
  padding: 20px;
  background: #2e3748;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

