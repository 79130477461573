<template>
  <section class="featured-projects">
    <div class="container">
      <h2 class="section-title">Featured Projects</h2>

      <div v-if="loading" class="loading-text">Loading projects...</div>
      <div v-else-if="projects.length === 0" class="no-projects-text">No projects available.</div>

      <div v-else class="projects-grid">
        <div class="project-card" v-for="project in projects" :key="project.id">
          <div class="card-image">
            <img :src="getProjectImage(project)" alt="Project Image" />
          </div>

          <div class="card-content">
            <h3 class="project-title">{{ project.title }}</h3>
            <div class="technologies">
              <span v-for="tech in project.technologies" :key="tech" class="tech-badge">{{ tech }}</span>
            </div>

            <div class="links">
              <a v-if="project.repositoryLink" :href="project.repositoryLink" target="_blank" class="repo-link">
                <i class="fab fa-github"></i> GitHub
              </a>
              <a v-if="project.demoLink" :href="project.demoLink" target="_blank" class="demo-link">
                <i class="fas fa-external-link-alt"></i> Live Demo
              </a>
            </div>

            <div v-if="project.files.length > 0" class="files-section">
              <h4>Files:</h4>
              <ul>
                <li v-for="file in project.files" :key="file">
                  <a :href="file" target="_blank">{{ getFileName(file) }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

        <!-- Pagination Controls -->
      <div v-if="totalPages > 1" class="pagination">
        <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
      </div>
    
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import viewClient from "@/services/viewClient";

export default {
  name: "FeaturedProjectsView",
  setup() {
    const projects = ref([]);
    const loading = ref(true);
    const currentPage = ref(1); // Current page state
    const itemsPerPage = ref(6);
    const totalPages = ref(1); // Total pages state

    const fetchProjects = async (page) => {
      try {
        const response = await viewClient.getAllProjects(page, itemsPerPage.value);
    
        projects.value =  response.data.projects;
        totalPages.value = response.data.totalPages;
        currentPage.value = response.data.currentPage;
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        loading.value = false;
      }
    };

    const getFileName = (url) => {
      return url.split("/").pop();
    };

    const getProjectImage = (project) => {
      if (project.repositoryLink) {
        return `${project.repositoryLink}/raw/master/docs/img/preview.png`; // Use direct link without fetch
      }
      // Use default placeholder if no valid image is found
      return require("../assets/project-placeholder.png"); // Make sure this file is inside the `public/assets/` folder
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        fetchProjects(page, itemsPerPage.value);
      }
    };

    onMounted(() => {
      fetchProjects(currentPage.value);
    });

    return { projects, loading, getFileName, getProjectImage, goToPage, currentPage, totalPages };
  },
};

</script>

<style scoped>
/* Featured Projects Section */
.featured-projects {
  background: linear-gradient(135deg, rgba(0, 0, 30, 0.9), rgba(20, 20, 40, 1));
  padding: 80px 0;
  color: #ffffff;
  text-align: center;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #61dafb;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Loading & No Projects Text */
.loading-text, .no-projects-text {
  font-size: 1.2rem;
  color: #b0b3c0;
}

/* Projects Grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 30px;
  justify-items: center;
}

/* Project Card */
.project-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  padding: 20px;
  border-radius: 12px;
  width: 350px;
  text-align: left;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: hidden;
}

.project-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(97, 218, 251, 0.3);
}

/* Project Image */
.card-image {
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Project Content */
.card-content {
  padding: 15px;
}

.project-title {
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
}

.project-description {
  font-size: 1rem;
  color: #b0b3c0;
  margin-bottom: 15px;
}

/* Technologies */
.technologies {
  margin-bottom: 15px;
}

.tech-badge {
  display: inline-block;
  background: linear-gradient(135deg, #43cea2, #185a9d);
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 0.85rem;
  color: #fff;
  margin-right: 5px;
}

/* Links */
.links {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.repo-link, .demo-link {
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.repo-link {
  background: #2575fc;
  color: #fff;
}

.demo-link {
  background: #43cea2;
  color: #fff;
}

.repo-link:hover, .demo-link:hover {
  transform: scale(1.05);
}

/* Files Section */
.files-section {
  margin-top: 15px;
}


.files-section h4 {
  font-size: 1rem;
  color: #61dafb;
  margin-bottom: 10px;
}

.files-section ul {
  list-style: none;
  padding: 0;
}

.files-section li {
  margin-bottom: 5px;
}

.files-section a {
  color: #43cea2;
  text-decoration: none;
  transition: 0.3s;
}

.files-section a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }

  .project-card {
    width: 90%;
  }
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  background-color: #61dafb;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #4f88d1;
}


.pagination span {
  color: #fff;
  font-size: 1rem;
}

.pagination button:disabled {
  background-color: #888;
  cursor: not-allowed;
}
</style>
