import apiClient from "./apiClient";

const projectClient = {
  /**
   * Fetch a paginated list of projects.
   * @param {Number} page - The current page number.
   * @param {Number} limit - The number of projects per page.
   * @returns {Promise} API response.
   */
  fetchProjects(page = 1, limit = 10) {
    return apiClient.get("/api/projects", { params: { page, limit } });
  },

  /**
   * Create a new project.
   * @param {Object} projectData - Data for the new project.
   * @returns {Promise} API response.
   */
  createProject(projectData) {
    return apiClient.post("/api/projects", projectData);
  },

  /**
   * Update a specific project.
   * @param {String} projectId - The ID of the project to update.
   * @param {Object} projectData - Updated project data.
   * @returns {Promise} API response.
   */
  updateProject(projectId, projectData) {
    return apiClient.put(`/api/projects/${projectId}`, projectData);
  },

  /**
   * Delete a specific project.
   * @param {String} projectId - The ID of the project to delete.
   * @returns {Promise} API response.
   */
  deleteProject(projectId) {
    return apiClient.delete(`/api/projects/${projectId}`);
  },

   /**
   * Archive a project.
   * @param {String} projectId - The ID of the project to archive.
   * @returns {Promise} API response.
   */
   archiveProject(projectId) {
    return apiClient.put(`/api/projects/${projectId}/archive`);
  },

  /**
   * Restore a project.
   * @param {String} projectId - The ID of the project to restore.
   * @returns {Promise} API response.
   */
  restoreProject(projectId) {
    return apiClient.put(`/api/projects/${projectId}/restore`);
  },
};

export default projectClient;
