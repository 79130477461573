import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import CSS for Toastification

// Import custom styles
import "./assets/style/main.css";
//import "./assets/style/theme.css";//

// Import font and dependencies
import "@fontsource/inter/index.css";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"

// CoreUI
import "@coreui/coreui/dist/css/coreui.min.css";
import { CIcon } from "@coreui/icons-vue";
import * as icons from "@coreui/icons";
import { CDropdown, CDropdownMenu, CDropdownToggle } from "@coreui/vue"; // CoreUI dropdown components
import store from "./store";

const app = createApp(App);

// Toastification configuration
app.use(Toast, {
  position: "top-right", // Customize Toastification position
  timeout: 5000, // Duration of toast notifications in milliseconds
  draggable: true, // Enable dragging of toasts
});

// Register CoreUI components globally
app.component("CIcon", CIcon);
app.component("CDropdown", CDropdown); // CoreUI dropdown
app.component("CDropdownMenu", CDropdownMenu); // CoreUI dropdown menu
app.component("CDropdownToggle", CDropdownToggle); // CoreUI dropdown toggle

// Provide CoreUI icons globally
app.provide("icons", icons);

app.use(store);
// Mount the app to the DOM
app.use(router).mount("#app");
