<template>
  <div class="landing-layout">
    <!-- Sticky & Scroll-Responsive Header -->
    <header :class="{ 'scrolled': isScrolled }">
      <NavBar />
    </header>

    <!-- Page Content -->
    <main>
      <router-view />
      <slot /> <!-- This will allow dynamic content to be inserted here -->
    </main>

    <!-- Footer -->
    <LandingFooter />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import NavBar from "@/components/NavBar.vue";
import LandingFooter from "@/components/LandingFooter.vue";

export default {
  components: {
    NavBar,
    LandingFooter,
  },
  setup() {
    const isScrolled = ref(false);

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 50; // Activate effect after 50px of scrolling
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return { isScrolled };
  },
};
</script>

<style scoped>
.landing-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #0f172a; /* Dark UI */
}

/* Sticky Navbar: Always Visible */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 15px 20px;
  transition: all 0.4s ease-in-out;
  background: rgba(15, 23, 42, 0.9); /* Default Dark Background */
  backdrop-filter: blur(10px);
  box-shadow: none;
}

/* Navbar Style When Scrolling */
header.scrolled {
  background: rgba(15, 23, 42, 0.8); /* Slightly more transparent */
  backdrop-filter: blur(15px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.02); /* Slight scaling effect */
}

/* Main content area */
main {
  padding-top: 80px;  /* Prevent content from hiding behind the sticky header */
  flex: 1;
  overflow-y: auto;
}
</style>
