import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.VITE_API_BASE_URL, // Replace with your backend base URL
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Automatically send HTTP-only cookies
  timeout: 10000, // Optional: Set a timeout for requests (10 seconds)
});

// 🛡️ **Request Interceptor**: Ensure secure authentication
apiClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    toast.error("Request error. Please try again.");
    return Promise.reject(error);
  }
);

//Response Interceptor: Handle authentication & errors globally
apiClient.interceptors.response.use(
  (response) => {
    if (response.data && response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      // 🔐 **Unauthorized (401): Redirect to Login**
      if (status === 401) {
        toast.error("Session expired. Please log in again.");
        window.location.href = "/login"; // Redirect to login
      }

      // 🚫 **Forbidden (403): Access Denied**
      else if (status === 403) {
        toast.error("Access denied: You do not have permission to perform this action.");
      }

      // 🔍 **Not Found (404): Resource Missing**
      else if (status === 404) {
        toast.error(data.message || "The requested resource was not found.");
      }

      // 🔥 **Server Error (500+)**
      else if (status >= 500) {
        toast.error("Server error. Please try again later.");
        console.error("Server error:", data.message || error.message);
      }

      // ⚠️ **Other HTTP Errors**
      else {
        toast.error(data.message || "An error occurred. Please try again.");
      }
    } else {
      // 🌐 **Network Error**
      toast.error("Network error. Please check your internet connection.");
      console.error("Network error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default apiClient;
