<template>
  <div class="form-container" v-if="!isSubmitted">
    <h3>{{ isEditMode ? "Edit Post" : "Create Post" }}</h3>
    <form @submit.prevent="savePost">
      <!-- Post Title -->
      <div class="form-group">
        <label>Post Title</label>
        <input type="text" v-model="post.title" placeholder="Enter post title" required maxlength="200" />
      </div>

      <!-- Content -->
      <div class="form-group">
        <label>Content</label>
        <textarea v-model="post.content" placeholder="Enter post content" required maxlength="5000"></textarea>
      </div>

      <!-- Categories -->
      <div class="form-group">
        <label>Categories (Max 10)</label>
        <input type="text" v-model="categoryInput" placeholder="Enter a category and press Enter" @keydown.enter.prevent="addCategory" />
        <div class="category-tags">
          <span v-for="(category, index) in post.categories" :key="index" class="category-tag">
            {{ category }} <button type="button" @click="removeCategory(index)">×</button>
          </span>
        </div>
      </div>

      <!-- Image Upload -->
      <div class="form-group">
        <label>Upload Images (Max 4)</label>
        <input type="file" @change="handleImageUpload" accept="image/*" multiple />
        <div v-if="imagePreviews.length" class="image-preview">
          <div v-for="(image, index) in imagePreviews" :key="index" class="preview-container">
            <img :src="image.preview" alt="Preview Image" />
            <button type="button" class="remove-img" @click="removeImage(index, image.id)">×</button>
          </div>
        </div>
      </div>

      <!-- Status -->
      <div class="form-group">
        <label>Status</label>
        <select v-model="post.status">
          <option value="Published">Published</option>
          <option value="Draft">Draft</option>
          <option value="Archived">Archived</option>
        </select>
      </div>

      <button type="submit" class="btn-submit">{{ isEditMode ? "Update Post" : "Create Post" }}</button>
    </form>
  </div>

  <!-- Success/Error Message Display -->
  <transition name="fade">
    <p v-if="serverMessage" class="server-message">{{ serverMessage }}</p>
  </transition>
</template>


<script>
import postClient from "@/services/postClient";
import userClient from "@/services/userClient";

export default {
  props: {
    isEditMode: { type: Boolean, default: false }, // Edit mode flag
    existingPost: { type: Object, default: null }, // Pre-filled post data
  },
  data() {
    return {
      post: {
        title: "",
        content: "",
        categories: [],
        status: "Draft",
        author: "", // Will be filled dynamically
        imageIds: [], // Stores uploaded image IDs
      },
      categoryInput: "", // For category input handling
      imagePreviews: [], // Image preview URLs
      isSubmitted: false, // Controls form visibility
      serverMessage: "", // Holds the response message
    };
  },
  methods: {
    async savePost() {
      try {
        // Ensure the uploaded images are saved with the post
        this.post.imageIds = this.imagePreviews.map((image) => image.id);

        let response;
        if (this.isEditMode) {
          response = await postClient.updatePost(this.post._id, this.post);
        } else {
          response = await postClient.createPost(this.post);
        }

        this.serverMessage = response.data.message || "Post saved successfully!";
        this.isSubmitted = true; // Hide form

        // Reset form after 3 seconds
        setTimeout(() => {
          this.serverMessage = "";
          this.isSubmitted = false;
          this.post = { title: "", content: "", categories: [], status: "Draft", author: "", imageIds: [] };
          this.imagePreviews = [];
        }, 3000);
      } catch (error) {
        this.serverMessage = "Failed to save post.";
        console.error("Failed to save post:", error);

        // Keep message for 3 seconds, then remove
        setTimeout(() => {
          this.serverMessage = "";
        }, 3000);
      }
    },

    async handleImageUpload(event) {
      const files = event.target.files;

      // Prevent exceeding the max limit
      if (this.imagePreviews.length + files.length > 4) {
        alert("You can upload a maximum of 4 images.");
        return;
      }

      for (let file of files) {
        try {
          const formData = new FormData();
          formData.append("file", file);

          // Upload image to the backend
          const response = await postClient.uploadImage(formData);
          const fileId = response.data.fileId; // Store the returned file ID

          // Add to image previews
          const previewUrl = URL.createObjectURL(file);
          this.imagePreviews.push({ preview: previewUrl, id: fileId });
        } catch (error) {
          console.error("Image upload failed:", error);
        }
      }
    },

    removeImage(index, fileId) {
      this.imagePreviews.splice(index, 1);
      this.post.imageIds = this.post.imageIds.filter((id) => id !== fileId);
    },

    addCategory() {
      if (this.categoryInput.trim() && this.post.categories.length < 10) {
        this.post.categories.push(this.categoryInput.trim());
        this.categoryInput = "";
      }
    },

    removeCategory(index) {
      this.post.categories.splice(index, 1);
    },

    async getPostAuthor() {
      try {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          const userData = JSON.parse(storedUser);
          return userData.id || "Unknown"; // Return user ID if available
        }

        const response = await userClient.getCurrentUser();
        return response.data._id || "Unknown";
      } catch (error) {
        console.error("Error fetching post author:", error);
        return "Unknown";
      }
    },
  },
  async mounted() {
    if (this.isEditMode && this.existingPost) {
      this.post = { ...this.existingPost };
      this.imagePreviews = this.existingPost.imageIds.map((id) => ({ id, preview: `/uploads/${id}` }));
    } else {
      this.post.author = await this.getPostAuthor(); // Assign logged-in user as author
    }
  },
};
</script>





<style scoped>
@import '../assets/style/FormStyles.css';

/* Category Tags */
.category-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.category-tag {
  background: #2e3748;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.category-tag button {
  background: transparent;
  border: none;
  color: #ff4d4f;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}

/* Image Upload */
.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.preview-container {
  position: relative;
  display: inline-block;
}

.preview-container img {
  max-width: 120px;
  max-height: 120px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.remove-img {
  position: absolute;
  top: 4px;
  right: 4px;
  background: #ff4d4f;
  border: none;
  color: white;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 50%;
  cursor: pointer;
}

/* Success/Error Message */
.server-message {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  background: #2e3748;
  color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
  animation: fadeIn 0.5s ease-in-out;
}

/* Fade Animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
