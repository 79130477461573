<template>
  <div class="modal-overlay" @click.self="onClose">
    <div class="modal-dialog">
      <div class="modal-header">
        <h5 class="modal-title">Change Password</h5>
        <button type="button" class="btn-close" @click="onClose">&times;</button>
      </div>
      <div class="modal-content">
        <form @submit.prevent="submitChangePassword">
          <div class="mb-3">
            <label for="password" class="form-label">New Password</label>
            <input
              type="password"
              id="password"
              v-model="password"
              class="form-control"
              placeholder="Enter new password"
              required
            />
          </div>
          <div class="mb-3">
            <label for="confirmPassword" class="form-label">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              v-model="confirmPassword"
              class="form-control"
              placeholder="Confirm new password"
              required
            />
          </div>
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="onClose">Cancel</button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      errorMessage: "",
    };
  },
  methods: {
    async submitChangePassword() {
      if (this.password !== this.confirmPassword) {
        this.errorMessage = "Passwords do not match.";
        return;
      }

      try {
        await this.$emit("change-password", { userId: this.userId, newPassword: this.password, confirmNewPassword: this.confirmPassword });
        this.onClose();
      } catch (error) {
        this.errorMessage = "Failed to change password. Please try again.";
        console.error(error);
      }
    },
    onClose() {
      this.password = "";
      this.confirmPassword = "";
      this.errorMessage = "";
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* Full-screen overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
}

/* Modal Dialog */
.modal-dialog {
  width: 100%;
  max-width: 480px;
  background: #2b2d42;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

/* Header */
.modal-header {
  background-color: #4e596f;
  color: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Content */
.modal-content {
  background: #323a49;
  color: #f3f4f7;
  padding: 20px;
}

/* Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background: #2b2d42;
  border-top: 1px solid #3b4055;
}

/* Buttons */
.btn {
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-close:hover {
  color: #c9c9c9;
}

/* Form */
.form-control,
.form-select {
  background: #3b4055;
  color: #ffffff;
  border: 1px solid #6261cc;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

.form-control:focus,
.form-select:focus {
  border-color: #aab3c5;
  outline: none;
  box-shadow: 0 0 8px rgba(98, 97, 204, 0.6);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
