<template>
  <div class="Logview p-4 text-light">
    <h2 class="mb-4">Admin Logs</h2>

    <!-- Tabs for Activity Logs & Uploaded Files Logs -->
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <button class="nav-link" :class="{ active: activeTab === 'activity' }" @click="activeTab = 'activity'">
          Activity Logs
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link" :class="{ active: activeTab === 'uploads' }" @click="activeTab = 'uploads'">
          Uploaded Files Logs
        </button>
      </li>
    </ul>

    <!-- Filters (Only for Activity Logs) -->
<div v-if="activeTab === 'activity'" class="row mb-3">
  <div class="col-md-3">
    <label class="form-label">Filter by User</label>
    <input type="text" v-model="filters.user" class="form-control" placeholder="Search user..." @input="fetchLogs" />
  </div>
  <div class="col-md-3">
    <label class="form-label">Filter by Action</label>
    <select v-model="filters.action" class="form-select" @change="fetchLogs">
      <option value="">All Actions</option>
      <option value="Created">Created</option>
      <option value="Updated">Updated</option>
      <option value="Deleted">Deleted</option>
      <option value="Archived">Archived</option>
      <option value="Restored">Restored</option>
      <option value="Logged In">Logged In</option>
      <option value="Logged Out">Logged Out</option>
    </select>
  </div>
  <div class="col-md-3">
    <label class="form-label">Filter by Target</label>
    <select v-model="filters.target" class="form-select" @change="fetchLogs">
      <option value="">All Targets</option>
      <option value="User">User</option>
      <option value="Project">Project</option>
      <option value="Post">Post</option>
      <option value="Publication">Publication</option>
    </select>
  </div>
  <div class="col-md-3">
    <label class="form-label">Filter by Time</label>
    <select v-model="filters.time" class="form-select" @change="fetchLogs">
      <option value="">All Time</option>
      <option value="24h">Last 24 Hours</option>
      <option value="7d">Last 7 Days</option>
      <option value="30d">Last 30 Days</option>
    </select>
  </div>
</div>

    <!-- Filters (Only for Uploaded Files Logs) -->
<div v-if="activeTab === 'uploads'" class="row mb-3">
  <div class="col-md-3">
    <label class="form-label">Filter by Filename</label>
    <input type="text" v-model="filters.filename" class="form-control" placeholder="Search filename..." @input="fetchUploads" />
  </div>
  <div class="col-md-3">
    <label class="form-label">Filter by File Type</label>
    <select v-model="filters.fileType" class="form-select" @change="fetchUploads">
      <option value="">All Types</option>
      <option value="docs">Documents (TXT, PDF)</option>
      <option value="images">Images (JPG, JPEG, PNG)</option>
    </select>
  </div>
  <div class="col-md-3">
    <label class="form-label">Filter by Uploaded By</label>
    <input type="text" v-model="filters.uploadedBy" class="form-control" placeholder="Uploader name..." @input="fetchUploads" />
  </div>
  <div class="col-md-3">
    <label class="form-label">Filter by Time</label>
    <select v-model="filters.uploadTime" class="form-select" @change="fetchUploads">
      <option value="">All Time</option>
      <option value="24h">Last 24 Hours</option>
      <option value="7d">Last 7 Days</option>
      <option value="30d">Last 30 Days</option>
    </select>
  </div>
</div>

    <!-- Activity Logs Table -->
    <TableComponent
      v-if="activeTab === 'activity'"
      :columns="activityColumns"
      :rows="filteredActivityLogs"
      :page="activityPage"
      :hasMorePages="hasMoreActivityPages"
      @page-change="changeActivityPage"
    />

    <!-- Uploaded Files Logs Table -->
    <TableComponent
      v-if="activeTab === 'uploads'"
      :columns="uploadsColumns"
      :rows="filteredUploads"
      :page="uploadsPage"
      :hasMorePages="hasMoreUploadsPages"
      @page-change="changeUploadsPage"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import logClient from "@/services/logClient"; // API client for logs

export default {
  components: { TableComponent },
  data() {
    return {
      activeTab: "activity", // Default tab
      filters: {
        user: "",
        action: "",
        time: "",
        target: "",
        filename: "",
        fileType: "",   
        uploadedBy: "", 
        uploadTime: "" 
        },
      // Activity Logs Data
      activityLogs: [],
      activityPage: 1,
      hasMoreActivityPages: true,

      // Uploaded Files Logs Data
      uploads: [],
      uploadsPage: 1,
      hasMoreUploadsPages: true,

      // Columns for Logs Table
      activityColumns: [
        { label: "User", key: "user" },
        { label: "Action", key: "action" },
        { label: "Details", key: "details" },
        {
          label: "Timestamp",
          key: "timestamp",
          render: (value) => new Date(value).toLocaleString(), // Format timestamp
        },
      ],
      uploadsColumns: [
        { label: "File Name", key: "fileName" },
        { label: "Type", key: "type" },
        { label: "Uploaded By", key: "uploadedBy" },
        {
          label: "Uploaded At",
          key: "uploadedAt",
          render: (value) => new Date(value).toLocaleString(),
        },
        {
          label: "Download",
          key: "downloadUrl",
          render: (value) =>
            value
              ? `<a href="${value}" target="_blank" class="btn btn-sm btn-outline-primary">Download</a>`
              : `<span class="text-muted">No File</span>`,
        },
      ],
    };
  },
  computed: {
    filteredActivityLogs() {
      return this.activityLogs.filter((log) => {
        return (
          (!this.filters.user || log.user.toLowerCase().includes(this.filters.user.toLowerCase())) &&
          (!this.filters.action || log.action.toLowerCase().includes(this.filters.action.toLowerCase())) &&
          (!this.filters.target || log.action.toLowerCase().includes(this.filters.target.toLowerCase())) &&
          (!this.filters.time || this.filterByTime(log.timestamp)) 
        );
      });
    },
    filteredUploads() {
      return this.uploads.filter((upload) => {
        const fileExtension = upload.fileName.split('.').pop().toLowerCase();
        const isDocument = ["txt", "pdf"].includes(fileExtension);
        const isImage = ["jpg", "jpeg", "png"].includes(fileExtension);

        return (
          (!this.filters.filename || upload.fileName.toLowerCase().includes(this.filters.filename.toLowerCase())) &&
          (!this.filters.fileType || 
            (this.filters.fileType === "docs" && isDocument) || 
            (this.filters.fileType === "images" && isImage)) &&
          (!this.filters.uploadedBy || upload.uploadedBy.toLowerCase().includes(this.filters.uploadedBy.toLowerCase())) &&
          (!this.filters.uploadTime || this.filterByTime(upload.uploadedAt)) // Apply time filter
        );
      });
    },
  },
  methods: {
    async fetchLogs() {
      try {
        const response = await logClient.fetchActivityLogs(this.activityPage, 10, this.filters);
        this.activityLogs = response.data.logs;
        this.hasMoreActivityPages = response.data.hasMorePages;
      } catch (error) {
        console.error("Failed to fetch logs:", error);
      }
    },
    async fetchUploads() {
      try {
        const response = await logClient.fetchFileUploadLogs(this.uploadsPage, this.uploadsLimit, this.filters);

        // Extract response data
        const { uploadsFile, totalPages, currentPage } = response.data;

        // Update component state with new data
        this.uploads = uploadsFile;

        // Update pagination status
        this.totalUploadsPages = totalPages;
        this.uploadsPage = currentPage;

      } catch (error) {
        console.error("🚨 Failed to fetch uploaded files:", error);
        this.uploads = []; // Fallback to empty state
      }
    },

    filterByTime(timestamp) {
      if (!timestamp) return false;

      const now = new Date();
      const logTime = new Date(timestamp);
      const timeFilter = this.filters.time || this.filters.uploadTime; // Ensure it works for both tabs

      if (timeFilter === "24h") {
        return logTime >= new Date(now - 24 * 60 * 60 * 1000);
      } else if (timeFilter === "7d") {
        return logTime >= new Date(now - 7 * 24 * 60 * 60 * 1000);
      } else if (timeFilter === "30d") {
        return logTime >= new Date(now - 30 * 24 * 60 * 60 * 1000);
      }

      return true; // Default: Show all logs
    },
    changeActivityPage(direction) {
      this.activityPage += direction;
      this.fetchLogs();
    },
    changeUploadsPage(direction) {
      this.uploadsPage += direction;
      this.fetchUploads();
    },
  },
  created() {
    this.fetchUploads();
    this.fetchLogs();
  },
};
</script>

<style scoped>
.Logview {
  background: linear-gradient(135deg, #1e293b, #0f172a);
  border-radius: 16px;
  padding: 2rem;
  animation: fadeIn 0.5s ease-in-out;
}

/* Tab Styling */
.nav-tabs {
  border-bottom: 2px solid #3b4055;
}
.nav-tabs {
  border-bottom: 2px solid #4e596f;
}

.nav-link {
  color: #e5e7eb;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}

.nav-link.active {
  background-color: #4e596f;
  color: #fff;
  border-radius: 8px;
}

.tab-content {
  padding: 1.5rem;
  background-color: #2b2d42;
  border-radius: 12px;
}

/* Table Search Inputs */
.form-control {
  background: #3b4055;
  color: #ffffff;
  border: 1px solid #6261cc;
  border-radius: 8px;
  padding: 10px;
}
.form-control:focus {
  border-color: #aab3c5;
  outline: none;
  box-shadow: 0 0 8px rgba(98, 97, 204, 0.6);
}

/* Table Pagination */
.btn {
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 8px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
