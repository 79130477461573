import { createRouter, createWebHistory } from "vue-router";
import store from "@/store"; // Import Vuex Store for Authentication

// Layouts and Views
import AdminLayout from "@/layouts/AdminLayout.vue";
import LandingLayout from "@/layouts/LandingLayout.vue";
import LandingPage from "@/views/LandingPage.vue";
import FeaturedProjects from "@/views/FeaturedProjects.vue";
import FeaturedPublications from "@/views/FeaturedPublications.vue";
import FeaturedPosts from "@/views/FeaturedPosts.vue";
import UsersView from "@/views/UsersView.vue";
import DashboardView from "@/views/DashboardView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import LoginView from "@/views/LoginView.vue";
import ProjectsView from "@/views/ProjectsView.vue";
import PostsView from "@/views/PostsView.vue";
import PublicationsView from "@/views/PublicationsView.vue";
import LogView from "@/views/LogView.vue";
import UserProfileView from "@/views/UserProfileView.vue";
import AccountSettingsView from "@/views/AccountSettingsView.vue";
import AdminHome from "@/views/AdminHome.vue";

const routes = [
  {
    path: "/",
    component: LandingLayout,
    children: [
      {
        path: "",
        name: "LandingPage",
        component: LandingPage,
      },
      {
        path: "projects",
        name: "FeaturedProjects",
        component: FeaturedProjects,
      },
      {
        path: "publications",
        name: "FeaturedPublications",
        component: FeaturedPublications,
      },
      {
        path: "blog",
        name: "FeaturedPosts",
        component: FeaturedPosts,
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { guestOnly: true, breadcrumb: "Login" }, // Redirect logged-in users
  },
  {
    path: "/admin",
    component: AdminLayout,
    meta: { requiresAuth: true, breadcrumb: "Admin" }, // Protect admin routes
    children: [
      {
        path: "",
        name: "AdminHome",
        component: AdminHome,
        meta: { breadcrumb: "Home" },
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: DashboardView,
        meta: { breadcrumb: "Dashboard" },
      },
      {
        path: "users",
        name: "Users",
        component: UsersView,
        meta: { breadcrumb: "Users", requiresRole: ["Admin"] }, // Restricted to Admin role
      },
      {
        path: "projects",
        name: "Project",
        component: ProjectsView,
        meta: { breadcrumb: "Projects", requiresRole: ["Admin", "Editor", "Viewer"] }, // Role-based access
      },
      {
        path: "posts",
        name: "Post",
        component: PostsView,
        meta: { breadcrumb: "Posts", requiresRole: ["Admin", "Editor"] }, // Restricted to Admin and Editor
      },
      {
        path: "publications",
        name: "Publication",
        component: PublicationsView,
        meta: { breadcrumb: "Publications", requiresRole: ["Admin", "Editor", "Viewer"] }, // Role-based access
      },
      {
        path: "logs",
        name: "Logs",
        component: LogView,
        meta: { breadcrumb: "Logs", requiresRole: ["Admin"] }, // Restricted to Admin
      },
      { 
        path: "profile",
        name: "Profile",
        component: UserProfileView,
        meta: { breadcrumb: "Profile", requiresRole: ["Admin", "Editor", "Viewer"]}, 
      },
      { 
        path: "settings",
        name: "Settings",
        component: AccountSettingsView,
        meta: { breadcrumb: "Settings", requiresRole: ["Admin", "Editor", "Viewer"]},
      },
    ],
  },
  // Catch-all route for undefined paths
  
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
    meta: { breadcrumb: "404 Not Found" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 🔐 **Navigation Guard for Authentication & Permissions**
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const userRole = store.getters.userRole; // e.g., "Admin", "Editor", "Viewer"

  // Redirect logged-in users away from guest-only routes (like Login)
  if (to.meta.guestOnly && isAuthenticated) {
    return next({ name: "Dashboard" }); // Redirect to Dashboard if logged in
  }

  //Protect routes that require authentication
  if (to.meta.requiresAuth && !isAuthenticated) {
    return next({ name: "Login" }); // Redirect to Login if not authenticated
  }

  //Check if the route requires specific roles
  if (to.meta.requiresRole && !to.meta.requiresRole.includes(userRole)) {
    return next({ name: "Dashboard" }); // Redirect unauthorized users to Dashboard
  }

  next(); // Proceed if all checks pass
});

export default router;
