<template>
  <footer class="footer">
    <div class="footer-container">
      <!-- Contact Details -->
      <div class="contact-info">
        <h3>Contact Us</h3>
        <p><i class="fas fa-envelope"></i> contact@idaslab.org</p>
        <p><i class="fas fa-phone"></i> +1 234 567 890</p>
        <p><i class="fas fa-map-marker-alt"></i> 123 Science Blvd, Innovation City</p>
      </div>

      <!-- Social Media Links -->
      <div class="social-links">
        <h3>Follow Us</h3>
        <div class="icons">
          <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
          <a href="#" target="_blank"><i class="fab fa-linkedin"></i></a>
          <a href="#" target="_blank"><i class="fab fa-github"></i></a>
          <a href="#" target="_blank"><i class="fab fa-youtube"></i></a>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="copyright">
      <p>&copy; 2025 IDAS Lab. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
import { onMounted } from "vue";
import gsap from "gsap";

export default {
  setup() {
    onMounted(() => {
      gsap.from(".footer-container", {
        opacity: 0,
        y: 40,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: ".footer",
          start: "top 90%",
        },
      });
    });
  },
};
</script>

<style scoped>
/* Footer Styling */
.footer {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 900px;
  margin: auto;
  gap: 30px;
}

.contact-info,
.social-links {
  flex: 1;
  min-width: 250px;
}

.contact-info h3,
.social-links h3 {
  color: #ffffff;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.contact-info p {
  color: #b0b3c0;
  font-size: 0.9rem;
  margin: 5px 0;
}

.contact-info i {
  margin-right: 8px;
  color: #61dafb;
}

/* Social Media Icons */
.social-links .icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.social-links a {
  color: #ffffff;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
}

.social-links a:hover {
  color: #61dafb;
  transform: scale(1.2);
}

/* Copyright */
.copyright {
  margin-top: 20px;
  color: #b0b3c0;
  font-size: 0.8rem;
}
</style>
