<template>
  <div class="file-upload-table">
    <table class="table table-striped table-dark">
      <thead>
        <tr>
          <th>File Name</th>
          <th>Type</th>
          <th>Uploaded By</th>
          <th>Uploaded At</th>
          <th>Download</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="file in uploads" :key="file.fileName">
          <td>{{ file.fileName || "Unknown" }}</td>
          <td>{{ file.type }}</td>
          <td>{{ file.uploadedBy || "Unknown" }}</td>
          <td>{{ formatDate(file.uploadedAt) }}</td>
          <td>
            <button
              class="btn btn-sm btn-outline-primary"
              @click="downloadFile(file.downloadUrl)"
            >
              Download
            </button>
          </td>
        </tr>
        <tr v-if="uploads.length === 0">
          <td colspan="6" class="text-center">No files available</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["uploads"], // Accepts the list of files as a prop
  methods: {
    formatDate(date) {
      return date ? new Date(date).toLocaleString() : "N/A";
    },
    downloadFile(url) {
      if (url) {
        window.open(url, "_blank"); // Open the file in a new tab for download
      } else {
        alert("File URL not available.");
      }
    },
  },
};
</script>
