<template>
  <section class="publications">
    <div class="container">
      <h2 class="section-title">Featured Publications</h2>

      <div v-if="loading" class="loading-text">Loading publications...</div>
      <div v-else-if="publications.length === 0" class="no-publications-text">No publications available.</div>

      <div v-else class="publications-grid">
        <div class="publication-card" v-for="publication in publications" :key="publication.id">
          <div class="card-header">
            <div :class="['publication-status', publication.status.toLowerCase()]">
              {{ publication.status }}  <!-- Display "Preprint" or "Published" -->
            </div>
            <div>
            <h3 class="publication-title">{{ publication.title }}</h3>
            </div>
          </div>

          <div class="card-body">
            <p class="publication-abstract">
              {{ getShortAbstract(publication.abstract) }}
            
            <button class="read-more-btn" @click="openModal(publication)">Read More</button>
          </p>
          </div>

          <div class="card-footer">
            <a v-if="publication.link" :href="publication.link" target="_blank" class="doi-link">
              <button class="view-article-btn">
                <span>View Article</span>
                <i class="fas fa-arrow-right"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination Controls -->
    <div v-if="totalPages > 1" class="pagination">
      <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
    </div>

    <!-- Modal with Animation -->
    <transition name="fade">
      <div v-if="selectedPublication" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
          <button class="close-btn" @click="closeModal">&times;</button>

          <div class="modal-body">
            <div class="modal-left">
              <h2 class="modal-title">{{ selectedPublication.title }}</h2>
              <p class="modal-abstract">{{ selectedPublication.abstract }}</p>
            </div>

            <div class="modal-right">
              <transition name="fade-slide">
                <div v-if="selectedPublication.authors.length" class="authors">
                  <h4>Authors:</h4>
                  <div class="author-list">
                    <span v-for="author in selectedPublication.authors" :key="author" class="author-badge">
                      {{ author }}
                    </span>
                  </div>
                </div>
              </transition>

              <transition name="fade-slide">
                <div v-if="selectedPublication.keywords.length" class="keywords">
                  <h4>Keywords:</h4>
                  <div class="keyword-list">
                    <span v-for="keyword in selectedPublication.keywords" :key="keyword" class="keyword-badge">
                      {{ keyword }}
                    </span>
                  </div>
                </div>
              </transition>
            </div>

          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import viewClient from "@/services/viewClient";

export default {
  name: "FeaturedPublications",
  setup() {
    const publications = ref([]);
    const loading = ref(true);
    const selectedPublication = ref(null);
    const currentPage = ref(1);
    const itemsPerPage = ref(4);
    const totalPages = ref(1);


    const fetchPublications = async (page) => {
      try {
        const response = await viewClient.getAllPublications(page, itemsPerPage.value);

        publications.value = response.data.publications || [];
        totalPages.value = response.data.totalPages;
        currentPage.value = response.data.currentPage;
      } catch (error) {
        console.error("Error fetching publications:", error);
      } finally {
        loading.value = false;
      }
    };

    const getShortAbstract = (abstract) => {
      return abstract.length > 100 ? abstract.substring(0, 100) + "..." : abstract;
    };

    const openModal = (publication) => {
      selectedPublication.value = publication;
    };

    const closeModal = () => {
      selectedPublication.value = null;
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        fetchPublications(page, itemsPerPage.value);
      }
    };

    onMounted(() => {
      fetchPublications(currentPage.value);
    });

    return { publications, loading, selectedPublication, goToPage, currentPage, totalPages, getShortAbstract, openModal, closeModal };
  },
};
</script>

<style scoped>
/* Publications Section */
.publications {
  background: linear-gradient(135deg, rgba(0, 0, 30, 0.9), rgba(20, 20, 40, 1));
  padding: 80px 0;
  color: #ffffff;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #61dafb;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Publications Grid */
.publications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 30px;
  justify-items: center;
}

/* Publication Card */
.publication-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  padding: 20px;
  border-radius: 15px;
  width: 500px;
  text-align: left;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, background-color 0.3s;
}

.publication-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(97, 218, 251, 0.3);
}

/* Publication Status Label */
/* Publication Status Label */
.publication-status {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 8px 15px;
  border-radius: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 20px rgba(255, 255, 255, 0.5);
}

/* Published Status (Green) */
.publication-status.published {
  background: linear-gradient(145deg, #00c853, #4caf50); /* Subtle green gradient */
  color: white;
  box-shadow: 0 4px 10px rgba(0, 196, 83, 0.5);
  transform: scale(1);
}

.publication-status.published:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 196, 83, 0.7);
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
}

/* Preprint Status (Blue) */
.publication-status.preprint {
  background: linear-gradient(145deg, #00aaff, #0066cc); /* Blue gradient */
  color: white;
  box-shadow: 0 4px 10px rgba(0, 170, 255, 0.5);
  transform: scale(1);
}

.publication-status.preprint:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 170, 255, 0.7);
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
}

/* Additional customizations */
.publication-status p {
  font-family: 'Roboto', sans-serif;
}


/* .view-article */
.view-article-btn {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 10px;
  transition: background-color 0.3s ease-in-out;
}

.view-article-btn:hover {
  background-color: #4f88d1;
}


/* Read More Button */
.read-more-btn {
  color: #00e5ff;
  border: none;
  font-size: 1rem;
  
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.read-more-btn:hover {
  color: #4f88d1;
}

/* Modal with Smooth Transitions */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}

.modal-content {
  background: rgba(255, 255, 255, 0.025);
  backdrop-filter: blur(15px);
  padding: 30px;
  border-radius: 12px;
  width: 70%;
  display: flex;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  animation: modal-fade-in 0.4s ease-out;
}

.modal-left {
  flex: 2;
  padding: 20px;
}

.modal-right {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.authors h4,
.keywords h4 {
  color: #61dafb;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

/* Author Badges */
.keyword-list,
.author-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 100px;
  overflow-y: auto;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.author-badge {
  background: rgba(255, 255, 255, 0.15);
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 0.95rem;
  color: #ffffff;
  text-transform: capitalize;
  white-space: nowrap;
}

.author-badge:hover {
  transform: scale(1.1);
  background: rgba(232, 242, 245, 0.4);
}

.keyword-badge {
  background: rgba(97, 218, 251, 0.2);
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 0.9rem;
  color: #61dafb;
  text-transform: uppercase;
  white-space: nowrap;
  transition: transform 0.2s ease-in-out;
}

.keyword-badge:hover {
  transform: scale(1.1);
  background: rgba(97, 218, 251, 0.4);
}

.modal-title {
  font-size: 1.8rem;
  color: #61dafb;
}

.modal-abstract {
  font-size: 1.1rem;
  color: #ebeaea;
}

@keyframes modal-fade-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 10;
}

.close-btn:hover {
  color: #f00;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  background-color: #61dafb;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #4f88d1;
}


.pagination span {
  color: #fff;
  font-size: 1rem;
}

.pagination button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

</style>
