<template>
  <div class="auth-container">
    <div class="login-card">
      <h2 class="text-center fw-bold">Welcome Back</h2>
      <p class="text-center text-muted mb-4">Sign in to your account</p>

      <!-- Error Notification -->
      <div v-if="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
        {{ errorMessage }}
        <button type="button" class="btn-close" @click="errorMessage = ''"></button>
      </div>

      <form @submit.prevent="handleLogin">
        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input
            type="email"
            id="email"
            v-model="email"
            class="form-control"
            placeholder="Enter your email"
            required
          />
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <div class="password-input">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              v-model="password"
              class="form-control"
              placeholder="Enter your password"
              required
            />
            <span class="toggle-password" @click="showPassword = !showPassword">
              <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
            </span>
          </div>
        </div>

        <button type="submit" class="btn btn-primary w-100 py-2" :disabled="isLoading">
          <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
          <span v-else>Login</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import apiClient from "@/services/apiClient";

export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      errorMessage: "",
      isLoading: false,
    };
  },
  methods: {
    async handleLogin() {
      this.isLoading = true;
      this.errorMessage = "";

      try {
        const response = await apiClient.post("/api/users/login", {
          email: this.email,
          password: this.password,
        });

        const { user } = response.data; // User info (No token needed)
        this.$store.dispatch("login", user); // Store user data in Vuex

        this.$router.push("/admin"); // Redirect to admin panel
      } catch (error) {
        console.log("Login Error:", error);
        this.errorMessage = error.response?.data?.message || "Login failed. Please check your credentials.";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Background Styling */
.auth-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1e293b, #0f172a);
}

/* Card Styling */
.login-card {
  background: rgba(40, 44, 52, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

/* Form Inputs */
.form-control {
  background: #242850;
  color: white;
  border: 1px solid #4e596f;
  border-radius: 8px;
  padding: 10px;
}

.form-control:focus {
  border-color: #6270c3;
  outline: none;
  box-shadow: 0 0 5px rgba(98, 112, 195, 0.5);
}

/* Password Field with Toggle */
.password-input {
  position: relative;
}

.password-input .toggle-password {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #aab3c5;
}

/* Buttons */
.btn {
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.btn-primary {
  background-color: #4e596f;
  border: none;
}

.btn-primary:hover {
  background-color: #6261cc;
}

/* Alert Notification */
.alert {
  border-radius: 8px;
  padding: 10px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-danger {
  background-color: rgba(255, 0, 0, 0.2);
  color: #ff4d4d;
}

.btn-close {
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 1.2rem;
  color: white;
}

.btn-close:hover {
  color: #e63946;
}
</style>
