<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ isEditMode ? "Edit Project" : "Add New Project" }}
          </h4>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <!-- Error Message -->
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>

          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="title" class="form-label">Title</label>
              <input
                type="text"
                id="title"
                v-model="projectData.title"
                class="form-control"
                required
              />
            </div>

            <div class="mb-3">
              <label for="description" class="form-label">Description</label>
              <textarea
                id="description"
                v-model="projectData.description"
                class="form-control"
                required
              ></textarea>
            </div>

            <div class="mb-3">
              <label for="technologies" class="form-label">Technologies (comma-separated)</label>
              <input
                type="text"
                id="technologies"
                v-model="projectData.technologies"
                class="form-control"
                placeholder="e.g., AI, Machine Learning"
              />
            </div>

            <div class="mb-3">
              <label for="repositoryLink" class="form-label">Repository Link</label>
              <input
                type="url"
                id="repositoryLink"
                v-model="projectData.repositoryLink"
                class="form-control"
              />
            </div>

            <div class="mb-3">
              <label for="demoLink" class="form-label">Demo Link</label>
              <input
                type="url"
                id="demoLink"
                v-model="projectData.demoLink"
                class="form-control"
              />
            </div>

            <div v-if="isEditMode" class="mb-3">
              <label for="status" class="form-label">Status</label>
              <select id="status" v-model="projectData.status" class="form-select">
                <option value="Active">Active</option>
                <option value="Archived">Archived</option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" @click="submitForm">
            {{ isEditMode ? "Save Changes" : "Add Project" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isEditMode: {
      type: Boolean,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "save"],
  data() {
    return {
      projectData: {...this.project},
      errorMessage: "",
    };
  },
  methods: {
    submitForm() {
      if (!this.projectData.title || !this.projectData.description) {
        this.errorMessage = "Title and Description are required!";
        return;
      }
      this.$emit("save", this.projectData);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
/* Full-screen overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
}

/* Modal Dialog */
.modal-dialog {
  width: 100%;
  max-width: 500px;
  background: #2b2d42;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

/* Header */
.modal-header {
  background-color: #4e596f;
  color: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Content */
.modal-content {
  background: #323a49;
  color: #f3f4f7;
  padding: 20px;
}

/* Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background: #2b2d42;
  border-top: 1px solid #3b4055;
}

/* Buttons */
.btn {
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-close:hover {
  color: #c9c9c9;
}

/* Form */
.form-control,
.form-select {
  background: #3b4055;
  color: #ffffff;
  border: 1px solid #6261cc;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

.form-control:focus,
.form-select:focus {
  border-color: #aab3c5;
  outline: none;
  box-shadow: 0 0 8px rgba(98, 97, 204, 0.6);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
