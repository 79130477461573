<template>
  <transition name="fade">
    <div class="modal-overlay" @click.self="onCancel">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
          </div>

          <!-- Modal Body -->
          <div class="modal-body">
            <p class="modal-message">{{ message }}</p>
          </div>

          <!-- Modal Footer -->
          <div class="modal-footer">
            <button class="btn btn-cancel" @click="onCancel">Cancel</button>
            <button class="btn btn-confirm" @click="handleConfirm">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    title: {
      type: String,
      default: "Are you sure?",
    },
    message: {
      type: String,
      default: "Do you want to proceed with this action?",
    },
  },
  emits: ["confirm", "cancel"],
  methods: {
    handleConfirm() {
      this.$emit("confirm"); // Emit confirmation event
    },
    onCancel() {
      this.$emit("cancel"); // Emit cancel event to close the modal
    },
  },
};
</script>

<style scoped>
/* Full-screen overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
}

/* Modal Dialog */
.modal-dialog {
  width: 100%;
  max-width: 420px;
  background: #2b2d42;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;
}

/* Header */
.modal-header {
  background-color: #4e596f;
  color: #fff;
  padding: 18px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Content */
.modal-content {
  background: #323a49;
  color: #f3f4f7;
  padding: 20px;
  text-align: center;
}

/* Footer */
.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #2b2d42;
  border-top: 1px solid #3b4055;
}

/* Buttons */
.btn {
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn-cancel {
  background-color: #6c757d;
  color: white;
}

.btn-cancel:hover {
  background-color: #5a6268;
}

.btn-confirm {
  background-color: #e63946;
  color: white;
}

.btn-confirm:hover {
  background-color: #c9303e;
}

/* Fade Animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
