<template>
  <div class="publication-management">
    <h2 class="mb-4 text-light">Publications Management</h2>

    <!-- Search and Actions -->
    <div class="d-flex justify-content-between align-items-center mb-3">
      <input
        type="search"
        v-model="search"
        placeholder="Search publications by title or author..."
        class="form-control search-input w-50"
      />
      <div class="d-flex gap-2">
        <button class="btn btn-success text-light" @click="openAddPublicationModal">
          <CIcon name="cil-plus" class="me-2" /> Add Publication
        </button>
        <button class="btn btn-primary" @click="fetchPublications">
          <CIcon name="cil-reload" class="me-2" /> Refresh
        </button>
      </div>
    </div>

    <!-- Error Notification -->
    <div v-if="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ errorMessage }}
      <button type="button" class="btn-close" @click="errorMessage = ''"></button>
    </div>

    <!-- Publications Table -->
    <TableComponent
      :columns="columns"
      :rows="filteredPublications"
      :page="page"
      :hasMorePages="hasMorePages"
      @page-change="changePage"
    >
      <template #actions="{ row }">
        <div class="d-flex gap-2">
          <button class="btn btn-sm btn-primary" @click="openEditModal(row)">Edit</button>
          <button
            class="btn btn-sm"
            :class="row.status === 'Archived' ? 'btn-success' : 'btn-warning'"
            @click="confirmToggleArchive(row)"
          >
            {{ row.status === 'Archived' ? 'Restore' : 'Archive' }}
          </button>
          <button
            class="btn btn-sm"
            :class="row.status === 'Published' ? 'btn-warning' : 'btn-success'"
            @click="confirmToggleStatus(row)"
          >
            {{ row.status === 'Published' ? 'Move to Preprint' : 'Publish' }}
          </button>
          <button class="btn btn-sm btn-danger" @click="confirmDeletePublication(row._id)">Delete</button>
        </div>
      </template>
    </TableComponent>

    <!-- Confirm Dialog -->
    <ConfirmDialog
      v-if="isConfirmDialogVisible"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      @confirm="confirmDialog.onConfirm"
      @cancel="closeConfirmDialog"
    />

    <!-- Add & Edit Publication Modal -->
    <PublicationModal
      v-if="showPublicationModal"
      :publication="currentPublication"
      :isEditMode="isEditMode"
      @save="savePublication"
      @close="closePublicationModal"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import PublicationModal from "@/components/PublicationModal.vue"; // Updated modal component
import publicationClient from "@/services/publicationClient";
import { CIcon } from "@coreui/icons-vue";

export default {
  components: { TableComponent, ConfirmDialog, PublicationModal, CIcon },
  data() {
    return {
      publications: [],
      search: "",
      page: 1,
      limit: 10,
      hasMorePages: true,
      errorMessage: "",
      showPublicationModal: false,
      isEditMode: false,
      currentPublication: null,
      isConfirmDialogVisible: false,
      confirmDialog: { title: "", message: "", onConfirm: null },
      columns: [
        { label: "Title", key: "title" },
        { label: "Authors", key: "authors", render: (value) => value.join(", ") },
        { label: "Journal", key: "journal" },
        { label: "Publication Date", key: "publicationDate", render: (value) => new Date(value).toLocaleDateString() },
        {
          label: "Link",
          key: "link",
          render: (value) => {
            if (!value) return `<span class="text-muted">No Link</span>`; // No link available
              return `<a href="${value}" target="_blank" class="text-info">View</a>`;
          },
      },
      {
        label: "File",
        key: "file",
        render: (value) => {
          if (!value) return `<span class="text-muted">No File</span>`; // No file available
          return `<button class="btn btn-sm btn-outline-primary file-download-btn" data-id="${value}"> Download PDF </button>`;
        },
      },
        { label: "Status", key: "status", render: (value) => this.formatStatus(value) },
      ],
    };
  },
  computed: {
    filteredPublications() {
      return this.search
        ? this.publications.filter(
            (publication) =>
              publication.title.toLowerCase().includes(this.search.toLowerCase()) ||
              publication.authors.some((author) => author.toLowerCase().includes(this.search.toLowerCase())) ||
              publication.status.toLowerCase().includes(this.search.toLowerCase())
          )
        : this.publications;
    },
  },
  methods: {
    async fetchPublications() {
      try {
        const response = await publicationClient.fetchPublications(this.page, this.limit);
        this.publications = response.data.publications;
        this.hasMorePages = response.data.hasMorePages;

        // Wait for table to render, then attach event listeners
      this.$nextTick(() => {
        document.querySelectorAll(".file-download-btn").forEach((button) => {
          button.addEventListener("click", (event) => {
            const fileId = event.target.getAttribute("data-id");
            this.downloadFile(fileId);
          });
        });
      });

      } catch (error) {
        this.errorMessage = "Failed to load publications.";
        console.error("Failed to fetch publications:", error);
      }
    },
    
    async downloadFile(fileId) {
      try {
        const response = await publicationClient.getFileUrl(fileId); // API call to get file URL
        const fileUrl = response.url;

        if (fileUrl) {
          window.open(fileUrl, "_blank"); // Open the file in a new tab
        } else {
          alert("File not available.");
        }
      } catch (error) {
        console.error("Error fetching file URL:", error);
        alert("Failed to retrieve file.");
      }
    },

    openAddPublicationModal() {
      this.isEditMode = false;
      this.currentPublication = {
        title: "",
        abstract: "",
        authors: "",
        journal: "",
        publicationDate: "",
        links: "",
        keywords:"",
        status: "Preprint",
      };
      this.showPublicationModal = true;
    },
    openEditModal(publication) {
      this.isEditMode = true;
      this.currentPublication = { ...publication };
      this.showPublicationModal = true;
    },
    closePublicationModal() {
      this.showPublicationModal = false;
    },
    async savePublication(publication) {
      try {
        if (this.isEditMode) {
          await publicationClient.updatePublication(publication._id, publication);
        } else {
          await publicationClient.createPublication(publication);
        }
        this.closePublicationModal();
        this.fetchPublications();
      } catch (error) {
        this.errorMessage = "Failed to save publication.";
        console.error("Failed to save publication:", error);
      }
    },
    confirmDeletePublication(publicationId) {
      this.showConfirmDialog("Delete Publication", "Are you sure?", () => this.deletePublication(publicationId));
    },
    async deletePublication(publicationId) {
      try {
        await publicationClient.deletePublication(publicationId);
        this.fetchPublications();
        this.isConfirmDialogVisible = false;
      } catch (error) {
        this.errorMessage = "Failed to delete publication.";
        console.error("Failed to delete publication:", error);
      }
    },
    confirmToggleArchive(publication) {
      const action = publication.status === "Archived" ? "restore" : "archive";
      this.showConfirmDialog(`${action} Publication`, `Are you sure you want to ${action} this publication?`, () =>
        this.toggleArchiveStatus(publication)
      );
    },
    async toggleArchiveStatus(publication) {
      try {
        if (publication.status === "Archived") {
          await publicationClient.restorePublication(publication._id);
          
        } else {
          await publicationClient.archivePublication(publication._id);
        }
        this.fetchPublications();
        this.isConfirmDialogVisible = false;
      } catch (error) {
        this.errorMessage = "Failed to update publication status.";
        console.error("Failed to update publication status:", error);
        this.isConfirmDialogVisible = false;
      }
    },
    confirmToggleStatus(publication) {
      const newStatus = publication.status === "Published" ? "Preprint" : "Published";
      this.showConfirmDialog(
        "Change Publication Status",
        `Are you sure you want to move this publication to ${newStatus}?`,
        async () => {
          await this.togglePublicationStatus(publication);
        }
      );
    },
    async togglePublicationStatus(publication) {
      try {
        const newStatus = publication.status === "Published" ? "Preprint" : "Published";
        await publicationClient.updatePublicationStatus(publication._id);

        // Update UI immediately (Optimistic Update)
        publication.status = newStatus;

        // Refresh Publications List
        this.fetchPublications();
        this.isConfirmDialogVisible = false;
      } catch (error) {
        console.error("Failed to update publication status:", error);
        this.isConfirmDialogVisible = false;
      }
    },
    showConfirmDialog(title, message, onConfirm) {
      this.confirmDialog = { title, message, onConfirm };
      this.isConfirmDialogVisible = true;
    },
    closeConfirmDialog() {
      this.isConfirmDialogVisible = false;
    },
    changePage(direction) {
      this.page += direction;
      this.fetchPublications();
    },
    formatStatus(status) {
      return `<span class="badge ${status === "Published" ? "bg-success" : status === "Preprint" ? "bg-info" : "bg-warning"}">${status}</span>`;
    },
  },
  created() {
    this.fetchPublications();
  },
};
</script>

<style scoped>
.publication-management {
  padding: 2rem;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  border-radius: 12px;
  animation: fadeIn 0.5s ease-in-out;
}

.search-input {
  border-radius: 8px;
  padding: 0.5rem;
  background-color: #323a49;
  color: #f3f4f7;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
