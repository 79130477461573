<template>
  <div class="col-md-4">
    <div class="card p-3 text-center" :class="cardClass">
      <h5 class="fw-bold">{{ title }}</h5>
      <h3 class="fw-bold">{{ value }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "value", "cardClass"],
};
</script>

<style scoped>
.card-blue {
  background-color: #007bff;
  color: white;
}
.card-purple {
  background-color: #6f42c1;
  color: white;
}
.card-orange {
  background-color: #fd7e14;
  color: white;
}
.card-red {
  background-color: #dc3545;
  color: white;
}
.card-green {
  background-color: #28a745;
  color: white;
}
.card-yellow {
  background-color: #ffc107;
  color: black;
}
.card-gray {
  background-color: #6c757d;
  color: white;
}
</style>
