<template>
  <div class="container-fluid project-management">
    <h2 class="mb-4 text-light">Project Management</h2>

    <!-- Search & Actions -->
    <div class="d-flex justify-content-between align-items-center mb-3">
      <input
        type="search"
        v-model="search"
        placeholder="Search Projects by title, content, category, or status..."
        class="form-control search-input w-50"
      />
      <div class="d-flex gap-2">
        <button class="btn btn-success text-light" @click="openAddProjectModal">
          <CIcon name="cil-plus" class="me-2" /> Add Project
        </button>
        <button class="btn btn-primary" @click="fetchProjects">
          <CIcon name="cil-reload" class="me-2" /> Refresh
        </button>
      </div>
    </div>

    <!-- Error Notification -->
    <div v-if="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ errorMessage }}
      <button type="button" class="btn-close" @click="errorMessage = ''"></button>
    </div>

    <!-- Projects Table -->
    <TableComponent
      :columns="columns"
      :rows="filteredProjects"
      :page="page"
      :hasMorePages="hasMorePages"
      @page-change="changePage"
    >
      <template #actions="{ row }">
        <div class="d-flex gap-2">
          <button class="btn btn-sm btn-primary" @click="openEditModal(row)">Edit</button>
          <button
            class="btn btn-sm"
            :class="row.status === 'Archived' ? 'btn-success' : 'btn-warning'"
            @click="confirmToggleArchive(row)"
          >
            {{ row.status === "Archived" ? "Restore" : "Archive" }}
          </button>
          <button class="btn btn-sm btn-danger" @click="confirmDeleteProject(row._id)">Delete</button>
        </div>
      </template>
    </TableComponent>

    <!-- Confirmation Dialog -->
    <ConfirmDialog
      v-if="showConfirmationDialog"
      :message="confirmationMessage"
      @confirm="handleConfirm"
      @cancel="handleCancel"
    />

    <!-- Add & Edit Project Modal -->
    <ProjectModal
      v-if="showModal"
      :project="currentProject"
      :isEditMode="isEditMode"
      @save="saveProject"
      @close="closeProjectModal"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import ProjectModal from "@/components/ProjectModal.vue"; // Reusable modal for Add/Edit
import projectClient from "@/services/projectClient";
import { CIcon } from "@coreui/icons-vue";

export default {
  components: {
    TableComponent,
    ConfirmDialog,
    ProjectModal,
    CIcon,
  },
  data() {
    return {
      projects: [],
      search: "",
      page: 1,
      limit: 10,
      hasMorePages: true,
      errorMessage: "",
      showConfirmationDialog: false,
      confirmationMessage: "",
      confirmationAction: null,
      showModal: false,
      isEditMode: false,
      currentProject: null,
      columns: [
        { label: "Title", key: "title" },
        { label: "Description", key: "description" },
        {
          label: "Technologies",
          key: "technologies",
          render: (value) => value.join(", "),
        },
        {
          label: "Repository",
          key: "repositoryLink",
          render: (value) =>
            `<a href="${value}" target="_blank" class="text-info">View Repo</a>`,
        },
        {
          label: "Demo",
          key: "demoLink",
          render: (value) =>
            `<a href="${value}" target="_blank" class="text-info">View Demo</a>`,
        },
        {
          label: "Status",
          key: "status",
          render: (value) =>
            `<span class="badge ${
              value === "Active" ? "bg-success" : "bg-warning"
            }">${value}</span>`,
        },
      ],
    };
  },
  computed: {
    filteredProjects() {
      if (!this.search) return this.projects;
      return this.projects.filter(
        (project) =>
          project.title.toLowerCase().includes(this.search.toLowerCase()) ||
          project.description.toLowerCase().includes(this.search.toLowerCase()) ||
          project.technologies.join(", ").toLowerCase().includes(this.search.toLowerCase()) ||
          project.status.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    async fetchProjects() {
      try {
        const response = await projectClient.fetchProjects(this.page, this.limit);
        this.projects = response.data.projects;
        this.hasMorePages = response.data.hasMorePages;
      } catch (error) {
        this.errorMessage = "Failed to load projects.";
        console.error("Failed to fetch projects:", error);
      }
    },
    confirmToggleArchive(project) {
      const action = project.status === "Archived" ? "restore" : "archive";
      this.confirmationMessage = `Are you sure you want to ${action} this project?`;
      this.confirmationAction = () => this.toggleArchiveStatus(project._id, project.status);
      this.showConfirmationDialog = true;
    },
    confirmDeleteProject(projectId) {
      this.confirmationMessage = "Are you sure you want to delete this project?";
      this.confirmationAction = () => this.deleteProject(projectId);
      this.showConfirmationDialog = true;
    },
    async deleteProject(projectId) {
      try {
        await projectClient.deleteProject(projectId);
        this.fetchProjects();
      } catch (error) {
        this.errorMessage = "Failed to delete project.";
        console.error("Failed to delete project:", error);
      }
    },
    handleConfirm() {
      if (this.confirmationAction) this.confirmationAction();
      this.showConfirmationDialog = false;
    },
    handleCancel() {
      this.showConfirmationDialog = false;
    },
    async toggleArchiveStatus(projectId, currentStatus) {
      try {
        if (currentStatus === "Archived") {
          await projectClient.restoreProject(projectId);
        } else {
          await projectClient.archiveProject(projectId);
        }
        this.fetchProjects();
      } catch (error) {
        this.errorMessage = "Failed to update project status.";
        console.error("Failed to update project status:", error);
      }
    },
    openAddProjectModal() {
      this.isEditMode = false;
      this.currentProject = {
        title: "",
        description: "",
        technologies: [],
        repositoryLink: "",
        demoLink: "",
      };
      this.showModal = true;
    },
    openEditModal(project) {
      this.isEditMode = true;
      this.currentProject = { ...project };
      this.showModal = true;
    },
    closeProjectModal() {
      this.showModal = false;
    },
    async saveProject(project) {
      try {
        if (this.isEditMode) {
          await projectClient.updateProject(project._id, project);
        } else {
          await projectClient.createProject(project);
        }
        this.closeProjectModal();
        this.fetchProjects();
      } catch (error) {
        this.errorMessage = "Failed to save project.";
        console.error("Failed to save project:", error);
      }
    },
    changePage(direction) {
      this.page += direction;
      this.fetchProjects();
    },
  },
  created() {
    this.fetchProjects();
  },
};
</script>

<style scoped>
.project-management {
  padding: 2rem;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  border-radius: 12px;
  animation: fadeIn 0.5s ease-in-out;
}

.search-input {
  border-radius: 8px;
  padding: 0.5rem;
  background-color: #323a49;
  color: #f3f4f7;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
