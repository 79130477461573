// services/userClient.js
import apiClient from "./apiClient";

const userClient = {
  /**
   * Fetch a paginated list of users.
   * @param {Number} page - The current page number.
   * @param {Number} limit - Number of users per page.
   * @returns {Promise} API response.
   */
  async fetchUsers(page = 1, limit = 10) {
    try {
      const response = await apiClient.get("/api/users", { params: { page, limit } });
      return response;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw new Error("Failed to fetch users. Please try again.");
    }
  },

  /**
   * Update the profile of a specific user (name and email).
   * @param {String} userId - The ID of the user.
   * @param {Object} payload - The user profile data to update (name, email).
   * @returns {Promise} API response.
   */
  async editUserProfile(userId, payload) {
    try {
      const response = await apiClient.put(`/api/users/${userId}/edit`, payload);
      return response;
    } catch (error) {
      console.error("Error updating user profile:", error);
      throw new Error("Failed to update user profile.");
    }
  },

  /**
   * Create a new user.
   * @param {Object} payload - The new user data (name, email, role, password).
   * @returns {Promise} API response.
   */
  async createUser(payload) {
    try {
      const response = await apiClient.post("/api/users/register", payload);
      return response;
    } catch (error) {
      console.error("Error creating user:", error);
      throw new Error("Failed to create user.");
    }
  },

  /**
   * Soft delete a specific user.
   * @param {String} userId - The ID of the user to delete.
   * @returns {Promise} API response.
   */
  async softDeleteUser() {
    try {
      const response = await apiClient.put(`/api/users/soft-delete`);
      return response;
    } catch (error) {
      console.error("Error soft deleting user:", error);
      throw new Error("Failed to delete user.");
    }
  },

  /**
   * Permanently delete a specific user.
   * @param {String} userId - The ID of the user to delete.
   * @returns {Promise} API response.
   */
  async permanentDeleteUser(userId) {
    try {
      const response = await apiClient.delete(`/api/users/${userId}`);
      return response;
    } catch (error) {
      console.error("Error permanently deleting user:", error);
      throw new Error("Failed to permanently delete user.");
    }
  },

  /**
   * Toggle the status of a specific user (activate/deactivate).
   * @param {String} userId - The ID of the user to toggle status.
   * @param {String} status - The new status for the user ("Active" or "Deactivated").
   * @returns {Promise} API response.
   */
  async toggleUserStatus(userId, status) {
    try {
      const endpoint = status === "Active"
        ? `/api/users/${userId}/deactivate`
        : `/api/users/${userId}/reactivate`;

      const response = await apiClient.put(endpoint);
      return response.data;
    } catch (error) {
      console.error("Error toggling user status:", error);
      throw new Error(`Failed to ${status === "Active" ? "deactivate" : "activate"} user.`);
    }
  },

  /**
   * Update the role of a user.
   * @param {String} userId - The ID of the user.
   * @param {String} role - The new role (Admin, Editor, Viewer).
   * @returns {Promise} API response.
   */
  async updateUserRole(userId, role) {
    try {
      const response = await apiClient.put(`/api/users/${userId}/role`, { role });
      return response.data;
    } catch (error) {
      console.error("Error updating user role:", error);
      throw new Error("Failed to update user role.");
    }
  },

  /**
   * Get details of the currently logged-in user.
   * @returns {Promise} API response.
   */
  async getCurrentUser() {
    try {
      const response = await apiClient.get("/api/users/me");
      return response;
    } catch (error) {
      console.error("Error fetching logged-in user:", error);
      throw new Error("Failed to fetch logged-in user details.");
    }
  },
  async logoutUser() {
    try {
      const response = await apiClient.post("/api/users/logout");
      return response;
    } catch (error) {
      console.error("Error logging out:", error);
      throw new Error("Logout failed. Please try again.");
    }
  },

  /**
   * Upload an image.
   * @param {FormData} formData - The image file in FormData format.
   * @returns {Promise<Object>} API response with the uploaded image URL.
   */
  async uploadProfileImage(formData) {
    try {
      const response = await apiClient.post("/api/uploads/avatar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  },

  /**
 * Change the password of the currently logged-in user.
 * @param {Object} passwordData - Contains currentPassword and newPassword.
 * @returns {Promise} API response.
 */
async changePassword(passwordData) {
  try {
    const response = await apiClient.put("/api/users/profile/change-password", passwordData);
    return response;
  } catch (error) {
    console.error("Error changing password:", error);
    throw new Error("Failed to change password. Please try again.");
  }
},

async editPassword(userId, passwordData) {
  try {
    const response = await apiClient.put(`/api/users/${userId}/edit-password`, passwordData);
    return response;
  } catch (error) {
    console.error("Error changing password:", error);
    throw new Error("Failed to change password. Please try again.");
  }
},

/**
 * Update the profile details of the currently logged-in user.
 * @param {Object} profileData - Contains updated user details (e.g., name, email).
 * @returns {Promise} API response.
 */
async updateProfile(profileData) {
  try {
    const response = await apiClient.put("/api/users/profile/update", profileData);
    return response;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw new Error("Failed to update profile. Please try again.");
  }
},


};

export default userClient;
