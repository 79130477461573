<template>
  <div class="form-container" v-if="!isSubmitted">
    <h3>{{ isEditMode ? "Edit Publication" : "Create Publication" }}</h3>
    <form @submit.prevent="savePublication">
      <!-- Publication Title -->
      <div class="form-group">
        <label>Publication Title</label>
        <input type="text" v-model="publication.title" placeholder="Enter publication title" required maxlength="300" />
      </div>

      <!-- Abstract -->
      <div class="form-group">
        <label>Abstract</label>
        <textarea v-model="publication.abstract" placeholder="Enter publication abstract" required></textarea>
      </div>

      <!-- Authors -->
      <div class="form-group">
        <label>Authors (Press Enter to add)</label>
        <input type="text" v-model="authorInput" placeholder="Enter author name and press Enter" @keydown.enter.prevent="addAuthor" />
        <div class="author-tags">
          <span v-for="(author, index) in publication.authors" :key="index" class="author-tag">
            {{ author }} <button type="button" @click="removeAuthor(index)">×</button>
          </span>
        </div>
      </div>

      <!-- Journal Name -->
      <div class="form-group">
        <label>Journal Name</label>
        <input type="text" v-model="publication.journal" placeholder="Enter journal name (optional)" />
      </div>

      <!-- Publication Date -->
      <div class="form-group">
        <label>Publication Date</label>
        <input type="date" v-model="publication.publicationDate" required />
      </div>

      <!-- External Link -->
      <div class="form-group">
        <label>External Link (Optional)</label>
        <input type="url" v-model="publication.link" placeholder="https://example.com/publication" />
      </div>

      <!-- File Upload -->
      <div class="form-group">
        <label>Upload File (PDF, DOCX, TXT) - Optional</label>
        <input type="file" @change="handleFileUpload" accept=".pdf,.docx,.txt" />
        <div v-if="uploadedFile" class="file-preview">
          <span>{{ uploadedFile.name }}</span>
          <button type="button" class="remove-file" @click="removeFile">×</button>
        </div>
      </div>

      <!-- Status -->
      <div class="form-group">
        <label>Status</label>
        <select v-model="publication.status">
          <option value="Published">Published</option>
          <option value="Preprint">Preprint</option>
          <option value="Archived">Archived</option>
        </select>
      </div>

      <button type="submit" class="btn-submit">{{ isEditMode ? "Update Publication" : "Publish" }}</button>
    </form>
  </div>

  <!-- Success/Error Message Display -->
  <transition name="fade">
    <p v-if="serverMessage" class="server-message">{{ serverMessage }}</p>
  </transition>
</template>


<script>
import publicationClient from "@/services/publicationClient";

export default {
  props: {
    isEditMode: { type: Boolean, default: false }, // Edit mode flag
    existingPublication: { type: Object, default: null }, // Pre-filled publication data
  },
  data() {
    return {
      publication: {
        title: "",
        abstract: "",
        authors: [],
        journal: "",
        publicationDate: "",
        link: "",
        fileId: null, // Stores uploaded file ID
        status: "Preprint",
      },
      authorInput: "", // For handling author input
      uploadedFile: null, // File preview
      isSubmitted: false, // Controls form visibility
      serverMessage: "", // Holds the response message
    };
  },
  methods: {
    async savePublication() {
      try {
        let response;
        if (this.isEditMode) {
          response = await publicationClient.updatePublication(this.publication._id, this.publication);
        } else {
          response = await publicationClient.createPublication(this.publication);
        }

        this.serverMessage = response.data.message || "Publication saved successfully!";
        this.isSubmitted = true; // Hide form

        // Reset form after 3 seconds
        setTimeout(() => {
          this.serverMessage = "";
          this.isSubmitted = false;
          this.publication = {
            title: "",
            abstract: "",
            authors: [],
            journal: "",
            publicationDate: "",
            link: "",
            fileId: null,
            status: "Preprint",
          };
          this.uploadedFile = null;
        }, 3000);
      } catch (error) {
        this.serverMessage = "Failed to save publication.";
        console.error("Failed to save publication:", error);

        // Keep message for 3 seconds, then remove
        setTimeout(() => {
          this.serverMessage = "";
        }, 3000);
      }
    },

    addAuthor() {
      if (this.authorInput.trim() && this.publication.authors.length < 10) {
        this.publication.authors.push(this.authorInput.trim());
        this.authorInput = "";
      }
    },

    removeAuthor(index) {
      this.publication.authors.splice(index, 1);
    },

    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      try {
        const formData = new FormData();
        formData.append("file", file);

        // Upload file to the backend
        const response = await publicationClient.uploadFile(formData);
        this.publication.fileId = response.data.fileId; // Store the returned file ID
        this.uploadedFile = file;
      } catch (error) {
        console.error("File upload failed:", error);
      }
    },

    removeFile() {
      this.uploadedFile = null;
      this.publication.fileId = null;
    },
  },
  mounted() {
    if (this.isEditMode && this.existingPublication) {
      this.publication = { ...this.existingPublication };
      this.uploadedFile = this.existingPublication.fileId ? { name: "Existing file" } : null;
    }
  },
};
</script>


<style scoped>
@import '../assets/style/FormStyles.css';

/* Author Tags */
.author-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.author-tag {
  background: #2e3748;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.author-tag button {
  background: transparent;
  border: none;
  color: #ff4d4f;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}

/* File Upload Preview */
.file-preview {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background: #2e3748;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
}

.file-preview span {
  flex-grow: 1;
}

.remove-file {
  background: transparent;
  border: none;
  color: #ff4d4f;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

/* Success/Error Message */
.server-message {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  background: #2e3748;
  color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 20px auto;
  animation: fadeIn 0.5s ease-in-out;
}

/* Fade Animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


</style>
