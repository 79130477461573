import apiClient from "./apiClient";

const publicationClient = {
  fetchPublications(page, limit) {
    return apiClient.get("/api/publications", { params: { page, limit } });
  },
  createPublication(data) {
    return apiClient.post("/api/publications", data);
  },
  updatePublication(id, data) {
    return apiClient.put(`/api/publications/${id}`, data);
  },
  archivePublication(id) {
    return apiClient.put(`/api/publications/${id}/soft-delete`);
  },
  restorePublication(id) {
    return apiClient.put(`/api/publications/${id}/restore`);
  },
  deletePublication(id) {
    return apiClient.delete(`/api/publications/${id}`);
  },
  updatePublicationStatus(id) {
    return apiClient.put(`/api/publications/${id}/toggle-status`);
  },
  uploadFile(formData) {
    return apiClient.post("/api/uploads", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  async getFileUrl(id) {
    try {
      const response = await apiClient.get(`/api/files/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch file URL for ID: ${id}`, error);
      throw error;
    }
  },
};

export default publicationClient;
